import React from "react";
import { Fragment } from "react";
import { Component } from "react";
import { Route } from "react-router";
import { ConfigureWidget } from "./ConfigureWidget";
import { WidgetPaths } from "./WidgetConstants";

export default class WidgetRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Route path={WidgetPaths.Edit} render={() => <ConfigureWidget />} />
      </Fragment>
    );
  }
}
