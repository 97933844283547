import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { ScaleLoader } from "react-spinners";

import { MenuItem } from "./MenuItem/MenuItem";
import { navigationMenuItems, productIdParam } from "../../NavigationItems";
import { useAppSelector } from "../../../../StateManagement/Hooks";
import { FetchingStatus } from "../../../../StateManagement/Features/FetchingStatus";
import replaceParameterValue from "../../CustomFunctions/ReplaceParameterValue";

import "./NavigationMenu.sass";

interface INavigationMenuProps {
  sectionName: string;
  setSectionName: (value: string) => void;
}

export const NavigationMenu: React.FC<INavigationMenuProps> = ({
  sectionName,
  setSectionName,
}) => {
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchingStatus =
      currentUserProductsFetchingStatus === FetchingStatus.NotStarted ||
      currentUserProductsFetchingStatus === FetchingStatus.Loading
        ? true
        : false;
    setIsLoading(fetchingStatus);
  }, [currentUserProductsFetchingStatus]);

  if (isLoading) {
    return (
      <React.Fragment>
        <Navbar className={"custom-navbar"} collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={"custom-navbar__collapse"}
          >
            <Nav className="flex-column me-auto">
              <ScaleLoader color={"#0E9292"}></ScaleLoader>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Navbar className={"custom-navbar"} collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={"custom-navbar__collapse"}
          >
            <Nav className="flex-column me-auto">
              {navigationMenuItems.map((item) => (
                <MenuItem
                  key={item.sectionName}
                  active={
                    sectionName.toLowerCase() ===
                    item.sectionName.replace("Manage ", "").toLowerCase()
                  }
                  sectionName={item.sectionName}
                  route={replaceParameterValue(
                    item.route,
                    productIdParam,
                    `/${currentProduct.productId!}`
                  )}
                  image={item.image}
                  setSectionName={setSectionName}
                />
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
};
