const apiPrefix =
  window.location.protocol + "//" + window.location.host + "/api/updates";

export const UpdatesApiPaths = {
  All: `${apiPrefix}/all`,
  Single: `${apiPrefix}/single`,
  NewsCount: `${apiPrefix}/updates-count`,
  Create: `${apiPrefix}/create`,
  Update: `${apiPrefix}/modify`,
  Delete: `${apiPrefix}/delete`,
  GetLabels: `${apiPrefix}/get-labels`,
  GetDashboardData: `${apiPrefix}/dashboard-data`,
  CreateTemporaryUpdate: `${apiPrefix}/create-temp-update`,
  DeleteTemporaryUpdate: `${apiPrefix}/delete-temp-update`,
  ModifyTemporaryUpdate: `${apiPrefix}/modify-temp-update`,
  UploadImage: `${apiPrefix}/upload-image`,
};

const authPrefix =
  window.location.protocol + "//" + window.location.host + "/api/authorization";

export const AuthPath = {
  Login: authPrefix + "/authorize",
  Logout: authPrefix + "/logout",
  GetUserState: authPrefix + "/get-user-state",
  MicrosoftKey: authPrefix + "/microsoft-application-id",
};

const productPrefix =
  window.location.protocol + "//" + window.location.host + "/api/products";

export const ProductPaths = {
  GetProductsForUser: productPrefix + "/get-products-user?userEmail=",
  ChangeProduct: productPrefix + "/change-product?productId=",
  AddProduct: productPrefix + "/add-product",
  UpdateProduct: productPrefix + "/update-product",
  DeleteProduct: productPrefix + "/delete-product?productId=",
  GetProductByPath: productPrefix + "/get-product-by-path?productPath=",
};
export const ProductInvitationTokenPrefix =
  window.location.protocol + "//" + window.location.host + "/invitation?token=";
