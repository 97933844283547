import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../AppStore";
import { ProductDTO } from "../../../common/dtos/ProductDTO";

const initialState: ProductDTO = new ProductDTO();

export const currentProductSlice = createSlice({
  name: "currentProduct",
  initialState,
  reducers: {
    setCurrentProduct: (state, action: PayloadAction<ProductDTO>) => {
      Object.assign(state, action.payload);
      state = action.payload;
    },
  },
});

export const { setCurrentProduct } = currentProductSlice.actions;

export const selectCurrentProduct = (state: RootState) => state;
