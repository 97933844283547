import React from "react";
import { useHistory } from "react-router";

import useRefresh from "../../../CustomFunctions/CustomRefresh";
import { UpdatesPaths } from "../../UpdatesConstants";
import { useAppSelector } from "../../../../../StateManagement/Hooks";
import { productIdParam } from "../../../NavigationItems";
import replaceParameterValue from "../../../CustomFunctions/ReplaceParameterValue";

import "./BackwardNavigation.sass";

interface IBackwardNavigation {}

export const BackwardNavigation: React.FC<IBackwardNavigation> = () => {
  const currentProductId = useAppSelector(
    (state) => state.currentProduct.productId
  );

  const refresh = useRefresh(
    useHistory(),
    replaceParameterValue(
      UpdatesPaths.News,
      productIdParam,
      `/${currentProductId!}`
    ),
    UpdatesPaths.News
  );

  return (
    <React.Fragment>
      <button className="back-to-list" onClick={() => refresh()}>
        <div className="back-to-list__arrow"></div>
        <div className="back-to-list__text">Back to list</div>
      </button>
    </React.Fragment>
  );
};
