import React, { useState } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

import { ProductActions } from "./ProductActions";
import { ProductDTO } from "../../../../common/dtos/ProductDTO";
import { UsersApiPath } from "../../Users/UsersApiPath";
import { ProductPaths } from "../../../../common/ApiRoutes/ApiRoutes";
import { setCurrentProduct } from "../../../../StateManagement/Features/CurrentProduct/CurrentProductSlice";
import {
  editProduct,
  removeProduct,
} from "../../../../StateManagement/Features/CurrentUserProductsList/UserProductsSlice";
import { setCurrentUser } from "../../../../StateManagement/Features/CurrentUser/CurrentUserSlice";
import {
  validateProductName,
  validateProductPath,
} from "../../CustomFunctions/ValidateProduct";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../StateManagement/Hooks";

import "./ProductAction.sass";
import { getErrorResponse } from "../../CustomFunctions/ProductResponse";

interface IProductActionsProps {
  className: string;
  action: ProductActions;
  products: ProductDTO[];
  product: ProductDTO | undefined;
  resetProducts: () => void;
}

enum ProductActionClasses {
  Delete = "remove-product product-actions-spacing",
  Edit = "edit-product product-actions-spacing",
  Leave = "leave-product product-actions-spacing",
}

export const ProductAction: React.FC<IProductActionsProps> = ({
  className,
  action,
  product,
  products,
  resetProducts,
}) => {
  const dispatch = useAppDispatch();
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUser = useAppSelector((state) => state.currentUser.user);

  const [invalidProductName, setInvalidProductName] = useState(false);
  const [invalidProductPath, setInvalidProductPath] = useState(false);
  const [productNameAlreadyExists, setProductNameAlreadyExists] =
    useState(false);
  const [productPathAlreadyExists, setProductPathAlreadyExists] =
    useState(false);

  const hasProductNameValidationErrors =
    invalidProductName || productNameAlreadyExists ? true : false;
  const hasProductPathValidationErrors =
    invalidProductPath || productPathAlreadyExists ? true : false;
  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState(false);
  const [updatedProductName, setUpdatedProductName] = useState(
    product?.productName
  );
  const [updatedProductPath, setUpdatedProductPath] = useState(
    product?.productPath || ""
  );

  const deleteProductMsg = "Are you sure you want to delete this product?";
  const leaveProductMsg = "Are you sure you want to leave this product?";

  const removeProductFromState = async (
    apiPath: string,
    errorMessage: string,
    productId: string | undefined
  ) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(apiPath + productId, requestOptions);
      if (!response.ok) {
        throw new Error(errorMessage);
      }
      setShow(false);
      dispatch(removeProduct(productId));
      if (currentProduct.productId === productId) {
        dispatch(setCurrentProduct(products[0]));

        dispatch(
          setCurrentUser({
            ...currentUser,
            role: products[0].currentUserRole,
          })
        );
      }
      resetProducts();
    } catch (e) {
      console.error(e);
    }
  };

  const leaveProduct = async () => {
    await removeProductFromState(
      UsersApiPath.LeaveProduct,
      "Unable to leave product",
      product?.productId
    );
  };

  const deleteProduct = async () => {
    await removeProductFromState(
      ProductPaths.DeleteProduct,
      "Unable to delete product",
      product?.productId
    );
  };

  const updateProduct = async (e: any) => {
    e.preventDefault();
    try {
      if (
        updatedProductName === product?.productName &&
        updatedProductPath === product?.productPath
      ) {
        setInvalidProductName(false);
        setProductNameAlreadyExists(false);
        setShow(false);

        setInvalidProductPath(false);
        setProductPathAlreadyExists(false);
        return;
      }

      const invalidProductName = await validateProductName(updatedProductName);
      setInvalidProductName(invalidProductName);

      const invalidProductPath = await validateProductPath(updatedProductPath);
      setInvalidProductPath(invalidProductPath);

      if (invalidProductName || invalidProductPath) {
        return;
      }

      let newProductDTO: ProductDTO = new ProductDTO();
      Object.assign(newProductDTO, product);
      newProductDTO.productName = updatedProductName;
      newProductDTO.productPath = updatedProductPath;

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newProductDTO),
      };
      const response = await fetch(ProductPaths.UpdateProduct, requestOptions);
      if (!response.ok) {
        throw new Error("Unable to update product");
      }

      const errorResponse = await getErrorResponse(response);

      if (errorResponse !== null) {
        setProductNameAlreadyExists(
          errorResponse.productNameExists &&
            updatedProductName?.toLowerCase() !==
              product?.productName?.toLowerCase()
        );
        setProductPathAlreadyExists(
          errorResponse.productPathExists &&
            updatedProductPath?.toLowerCase() !==
              product?.productPath?.toLowerCase()
        );

        const alreadyExistingValidationErrors =
          errorResponse.productNameExists || errorResponse.productPathExists;

        if (alreadyExistingValidationErrors) {
          return;
        }
      }

      setShow(false);
      dispatch(editProduct(newProductDTO));
      if (currentProduct.productId === product?.productId) {
        dispatch(
          setCurrentProduct({
            ...currentProduct,
            productName: updatedProductName,
            productPath: updatedProductPath,
          })
        );
      }

      resetProducts();
      setValidated(false);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteProductComponent = (
    <React.Fragment>
      <div
        className={`${ProductActionClasses.Delete} ${className}`}
        onClick={() => setShow(true)}
      ></div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete product</Modal.Title>
        </Modal.Header>
        <Modal.Body>{deleteProductMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="light"
            className={"confirm-action"}
            onClick={() => {
              deleteProduct();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  const leaveProductComponent = (
    <React.Fragment>
      <div
        className={`${ProductActionClasses.Leave} ${className}`}
        onClick={() => setShow(true)}
      ></div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Leave product</Modal.Title>
        </Modal.Header>
        <Modal.Body>{leaveProductMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="light"
            className={"confirm-action"}
            onClick={() => {
              leaveProduct();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  const editProductComponent = (
    <React.Fragment>
      <div
        className={`${ProductActionClasses.Edit} ${className}`}
        onClick={() => setShow(true)}
      ></div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} id="newProductForm">
            <Form.Group className="mb-3" controlId="formProductName">
              <Form.Label>Product name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  isInvalid={hasProductNameValidationErrors}
                  placeholder="Enter product name"
                  required
                  value={updatedProductName}
                  onChange={(e) => {
                    setUpdatedProductName(e.target.value);
                  }}
                />
                {invalidProductName ? (
                  <Form.Control.Feedback type="invalid">
                    Invalid product name, must be at least 3 characters long and
                    at most 20 characters long.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
                {productNameAlreadyExists ? (
                  <Form.Control.Feedback type="invalid">
                    Product name already exists.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
              </InputGroup>

              <br />

              <Form.Label>Product URL path</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  isInvalid={hasProductPathValidationErrors}
                  placeholder="Enter product path"
                  required
                  value={updatedProductPath}
                  onChange={(e) => {
                    setUpdatedProductPath(e.target.value);
                  }}
                />
                {invalidProductPath ? (
                  <Form.Control.Feedback type="invalid">
                    Invalid product path, must be at least 3 characters long and
                    at most 20 characters long, and must consist of only lower
                    case letters, numbers and dashes.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
                {productPathAlreadyExists ? (
                  <Form.Control.Feedback type="invalid">
                    Product URL path already exists.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="light"
            type="submit"
            className={"confirm-action"}
            onClick={(e) => {
              updateProduct(e);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  switch (action) {
    case ProductActions.Delete:
      return deleteProductComponent;
    case ProductActions.Leave:
      return leaveProductComponent;
    case ProductActions.Edit:
      return editProductComponent;
    default:
      return <React.Fragment></React.Fragment>;
  }
};
