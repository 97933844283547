import React from "react";
import { Link } from "react-router-dom";

import "./MenuItem.sass";

interface IMenuItemProps {
  active: boolean;
  sectionName: string;
  route: string;
  image: string;
  setSectionName: (name: string) => void;
}

export const MenuItem: React.FC<IMenuItemProps> = ({
  active,
  sectionName,
  route,
  image,
  setSectionName,
}) => {
  return (
    <Link
      className={`menu-item__link`}
      to={route}
      onClick={() => setSectionName(sectionName.replace("Manage ", ""))}
    >
      <div className="menu-item d-flex flex-row justify-content-start align-items-center">
        <div className={`menu-item__border ${active ? "active" : ""}`}></div>
        <div
          className={`menu-item__icon ${
            active ? "active" : ""
          } d-none d-xl-block`}
          style={{
            maskImage: `url(/Content/images/left-menu${image})`,
            WebkitMaskImage: `url(/Content/images/left-menu${image})`,
          }}
        ></div>
        <div className={`menu-item__text ${active ? "active" : ""}`}>
          {sectionName}
        </div>
      </div>
    </Link>
  );
};
