import { UpdatesApiPaths } from "../../../common/ApiRoutes/ApiRoutes";
import { IUpdate, IUpdateBody } from "../../../common/dtos/NewsDto";

export interface IApiResponse {
  status: boolean;
  error?: string;
  statusText?: string;
}

export const saveUpdate = async (
  updateBody: IUpdateBody,
  id?: string
): Promise<IApiResponse> => {
  const createUpdate = async (
    updateBody: IUpdateBody
  ): Promise<IApiResponse> => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateBody),
    };
    const response = await fetch(UpdatesApiPaths.Create, requestOptions);
    if (response.ok) {
      return { status: true };
    } else {
      return {
        status: false,
        error: "Bad response",
        statusText: response.statusText,
      };
    }
  };

  const refreshUpdate = async (
    updateBody: IUpdateBody
  ): Promise<IApiResponse> => {
    const update: IUpdate = { id: id!, body: updateBody };
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update),
    };
    const response = await fetch(UpdatesApiPaths.Update, requestOptions);
    if (response.ok) {
      return { status: true };
    } else {
      return {
        status: false,
        error: "Bad response",
        statusText: response.statusText,
      };
    }
  };

  if (id !== undefined) {
    return await refreshUpdate(updateBody);
  } else {
    return await createUpdate(updateBody);
  }
};
