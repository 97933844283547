export const validateProductName = async (name: string | undefined) => {
  if (name !== undefined && (name!.length < 3 || name!.length > 20)) {
    return true;
  }
  return false;
};

export const validateProductPath = async (path: string | undefined) => {
  const regex = new RegExp("^[a-zA-Z0-9-]{3,20}$");

  if (path !== undefined && !regex.test(path)) {
    return true;
  }
  return false;
};
