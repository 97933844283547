import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './CustomCardButton.sass';

interface ICustomCardButtonProps {
    buttonUrl?: string,
    buttonText?: string,
    buttonIconUrl?: string
}

export const CustomCardButton: React.FC<ICustomCardButtonProps> = ({buttonUrl, buttonText, buttonIconUrl}) => {

    return <React.Fragment>
        {buttonUrl === undefined ? '' : (
                        <Link className={"card-button d-flex justify-content-center align-items-center"} to={buttonUrl}>
                            <Button variant="light" className={"card-button__button d-flex justify-content-center align-items-center"}>
                                {buttonIconUrl === undefined ? '' : (
                                    <div className="card-button__button__icon" style={{ maskImage: `url(/Content/images/${buttonIconUrl})` }}></div>
                                )}
                                <div className="card-button__button__text">{buttonText}</div>
                            </Button>
                        </Link>
                    )}
    </React.Fragment>
}
