import React from "react";

import "./CustomTooltip.sass";

interface ICustomTooltipProps extends React.PropsWithChildren {
  fullDescription: string;
}

export const CustomTooltip: React.FC<ICustomTooltipProps> = (props) => {
  return (
    <React.Fragment>
      <div className="custom-tooltip__main-content">{props.children}</div>
      <div className={`custom-tooltip`}>{props.fullDescription}</div>
    </React.Fragment>
  );
};
