import React from "react";
import {
  NewsCard,
  productUpdatesToNewsEntries,
  useInfiniteScroll,
  useProductUpdates,
} from "@7pace/whats-new";
import { SlotSize, useSlot } from "@7pace/design";
import styled from "styled-components";

export type NewsProps = {
  title: string;
  baseUrl: string;
  productId: string;
};

export const News: React.FC<NewsProps> = ({ title, baseUrl, productId }) => {
  const LoaderSlot = useSlot("loader");

  const {
    productUpdates,
    hasMoreUpdates,
    isFetchingProductUpdates,
    loadMoreUpdates,
  } = useProductUpdates(
    {
      baseUrl,
      productId,
    },
    "1"
  );

  const { loaderWrapperRef } = useInfiniteScroll(loadMoreUpdates);

  const renderUpdates = () => {
    if (productUpdates) {
      return productUpdatesToNewsEntries(productUpdates).map(
        (newsEntry, index, { length }) => {
          return (
            <>
              <NewsCard
                key={newsEntry.id}
                {...newsEntry}
                showIndicator={false}
              />

              {index !== length - 1 && <NewsSeparator />}
            </>
          );
        }
      );
    }
  };

  const renderLoader = () => {
    if (
      (productUpdates.length !== 0 && hasMoreUpdates) ||
      isFetchingProductUpdates
    ) {
      return (
        <LoaderWrapper ref={loaderWrapperRef}>
          <LoaderSlot size={SlotSize.Small} />
        </LoaderWrapper>
      );
    }
  };

  return (
    <div>
      <Title>{title}</Title>

      <ContentWrapper>
        {renderUpdates()}
        {renderLoader()}
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.l};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NewsSeparator = styled.div`
  height: 1px;
  width: 100%;

  margin: ${(p) => p.theme.spacing.l} 0;

  background: ${(p) => p.theme.color.border.layout.subtle};
`;

const Title = styled.h1`
  font: ${(p) => p.theme.typography.title.l};
  padding: ${(p) => p.theme.spacing.l} 0;
`;
