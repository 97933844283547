import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import MDEditor from "@uiw/react-md-editor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { IUpdateBody } from "../../../../../common/dtos/NewsDto";
import { CustomBootstrapDropdownItem } from "./Dropdown/Dropdown";
import { UpdatesApiPaths } from "../../../../../common/ApiRoutes/ApiRoutes";
import { useAppSelector } from "../../../../../StateManagement/Hooks";

import "./RenderUpdate.sass";
import { ImageUploader } from "./ImageUploader/ImageUploader";

interface IRenderUpdateProps {
  update: IUpdateBody;
  setUpdate: (update: IUpdateBody) => void;
  setRefresh: (value: boolean) => void;
  isEdit: boolean;
}

interface ILablesApiResponse {
  title: string;
  id: string;
}

export const RenderUpdate: React.FC<IRenderUpdateProps> = ({
  update,
  setUpdate,
  setRefresh,
  isEdit,
}: IRenderUpdateProps) => {
  dayjs.extend(utc);

  const currentProduct = useAppSelector((state) => state.currentProduct);
  const [isPublishLater, setPublish] = useState(isEdit);
  const [dateTime, setDate] = useState({
    date: dayjs(isEdit === true ? update.date : new Date())
      .utc()
      .local()
      .format()
      .substr(0, 10),
    time: dayjs(isEdit === true ? update.date : new Date())
      .utc()
      .local()
      .format()
      .substr(11, 5),
  });
  const [labels, setLabels] = useState<[ILablesApiResponse]>([
    { title: "", id: "" },
  ]);
  const [description, setDescription] = useState(isEdit ? update.body : "");
  const [updateLabel, setUpdateLabel] = useState(isEdit ? update.label : "0");

  useEffect(() => {
    async function getLabels() {
      const url = UpdatesApiPaths.GetLabels;
      try {
        const response = await fetch(url);
        if (response.ok) {
          var data = (await response.json()) as [ILablesApiResponse];
          setLabels(data);
        } else {
          throw new Error("Bad api response");
        }
      } catch (e) {
        console.error(e);
      }
    }

    getLabels();
  }, [currentProduct.productId]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    label?: string,
    body?: string,
    publish?: boolean
  ) => {
    const updateBody: IUpdateBody = update;

    let date = dateTime.date;
    let time = dateTime.time;

    if (body !== undefined) {
      updateBody.body = body;
      setDescription(body);
    } else {
      if (label !== undefined) {
        updateBody.label = label;
        setUpdateLabel(label);
      } else {
        if (event === undefined) {
          updateBody.body = description;
        } else {
          switch (event.target.name) {
            case "title":
              updateBody.title = event.target.value;
              break;
            case "version":
              updateBody.version = event.target.value;
              break;
            case "date":
              setDate({ date: event.target.value, time: dateTime.time });
              date = event.target.value;
              break;
            case "time":
              setDate({ date: dateTime.date, time: event.target.value });
              time = event.target.value;
              break;
            case "publish":
              setPublish(!isPublishLater);
              break;
          }
        }
      }
    }

    updateBody.date =
      publish === undefined
        ? isPublishLater === true
          ? dayjs()
              .set("year", parseInt(date.substr(0, 4)))
              .set("month", parseInt(date.substr(5, 2)) - 1) //because numeration of months starts with 0
              .set("date", parseInt(date.substr(8, 2)))
              .set("hour", parseInt(time.substr(0, 2)))
              .set("minute", parseInt(time.substr(3, 2)))
              .set("second", 0)
              .format()
          : dayjs().utc().local().format()
        : publish === true
        ? dayjs()
            .set("year", parseInt(date.substr(0, 4)))
            .set("month", parseInt(date.substr(5, 2)) - 1) //because numeration of months starts with 0
            .set("date", parseInt(date.substr(8, 2)))
            .set("hour", parseInt(time.substr(0, 2)))
            .set("minute", parseInt(time.substr(3, 2)))
            .set("second", 0)
            .format()
        : dayjs().utc().local().format();

    setUpdate(updateBody);
    setRefresh(true);
  };

  const handleImageMarkdownStringGenerated = (markdownString: string) => {
    onChange(undefined, "", description + "\n" + markdownString);
  };

  const headline = (
    <div className={"modify-update__headline d-flex flex-column"}>
      <span>Headline</span>
      {isEdit === true ? (
        <input
          name="title"
          className={"headline"}
          onChange={(e) => onChange(e)}
          id="title"
          defaultValue={update.title}
        />
      ) : (
        <input
          name="title"
          className={"headline"}
          onChange={(e) => onChange(e)}
          id="title"
          placeholder={"Add a title"}
        />
      )}
    </div>
  );

  const bootstrapDropdown = (
    <div className="d-flex flex-column">
      <span>Type</span>
      <Dropdown>
        <Dropdown.Toggle
          className={"custom-bootstrap-dropdown"}
          id="dropdown-basic"
        >
          {labels.find((l) => l.id === updateLabel) === undefined
            ? ""
            : labels.find((l) => l.id === updateLabel)?.title}
        </Dropdown.Toggle>

        <Dropdown.Menu className={"custom-bootstrap-dropdown__menu"}>
          {labels.map((label) => (
            <CustomBootstrapDropdownItem
              key={label.id}
              label={label.title}
              labelId={label.id}
              onChange={onChange}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const version = (
    <div className="d-flex flex-column">
      <span>Version</span>
      {isEdit === true ? (
        <input
          name="version"
          className={"version"}
          onChange={(e) => onChange(e)}
          id="version"
          defaultValue={update.version}
        />
      ) : (
        <input
          name="version"
          className={"version"}
          onChange={(e) => onChange(e)}
          id="version"
          placeholder={"Add a version title"}
        />
      )}
    </div>
  );

  const publishLater = (
    <div className="d-flex justify-content-between">
      <span>Publish later</span>
      <label className="toggle">
        {isPublishLater === true ? (
          <input
            className={"checkbox"}
            type="checkbox"
            name="publish"
            defaultChecked
            onChange={() => {
              setPublish((prevState) => !prevState);
              onChange(undefined, undefined, undefined, !publishLater);
            }}
          />
        ) : (
          <input
            className={"checkbox"}
            type="checkbox"
            name="publish"
            onChange={() => {
              setPublish((prevState) => !prevState);
              onChange(undefined, undefined, undefined, !publishLater);
            }}
          />
        )}
        <span className="slider round"></span>
      </label>
    </div>
  );

  const releaseDate = (
    <div
      className={"modify-update__release-date d-flex justify-content-between"}
    >
      {isPublishLater && (
        <React.Fragment>
          <div className="d-flex flex-column">
            <span>Date:</span>
            <input
              className={"datetime"}
              type="date"
              name="date"
              defaultValue={dateTime.date}
              onChange={(e) => onChange(e)}
              id="date"
            />
          </div>
          <div className="d-flex flex-column">
            <span>Time:</span>
            <input
              className={"datetime"}
              type="time"
              name="time"
              defaultValue={dateTime.time}
              onChange={(e) => onChange(e)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );

  const editor = (
    <div className={"modify-update"}>
      <span>Full description</span>
      {isEdit === true ? (
        <MDEditor
          className={"editor"}
          height={420}
          preview={"edit"}
          extraCommands={[]}
          hideToolbar
          value={description}
          onChange={(value) => {
            onChange(undefined, "", value);
          }}
          previewOptions={{
            linkTarget: "_blank",
          }}
        />
      ) : (
        <MDEditor
          className={"editor"}
          height={420}
          preview={"edit"}
          extraCommands={[]}
          hideToolbar
          placeholder={"Add news"}
          value={description}
          onChange={(value) => {
            onChange(undefined, "", value);
          }}
          previewOptions={{
            linkTarget: "_blank",
          }}
        />
      )}
    </div>
  );

  return (
    <React.Fragment>
      <div className={"update-body"}>
        <div className="modify-update__header d-flex flex-column">
          {headline}
          <div className={"d-flex justify-content-between"}>
            {bootstrapDropdown}
            {version}
          </div>
        </div>

        <div
          className={`modify-update__release-options ${
            isPublishLater ? "open" : ""
          }`}
        >
          {publishLater}
          {releaseDate}
        </div>
        <div className="separator"></div>
        {
          <ImageUploader
            onImageMarkdownStringGenerated={handleImageMarkdownStringGenerated}
          />
        }
        <br />
        {editor}
      </div>
    </React.Fragment>
  );
};
