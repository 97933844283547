import { immerable } from "immer";

export class UserDTO {
  [immerable] = true;
  email?: string;
  name?: string;
  role?: string;
  productId?: string;
  isAuthenticated?:boolean;
}
