import {
  IUpdate,
  IUpdateBody,
  Label,
  LabelStrings,
} from "../../../common/dtos/NewsDto";

export const emptyUpdateBody = (): IUpdateBody => ({
  label: "",
  title: "",
  version: "",
  date: new Date().toISOString().substr(0, 10),
  body: "",
});

export const stringToLabel = (value: string): Label => {
  switch (value) {
    case LabelStrings.Update:
      return Label.Update;
    case LabelStrings.Blog:
      return Label.Blog;
    case LabelStrings.Feature:
      return Label.Feature;
    case LabelStrings.Release:
      return Label.Release;
    default:
      return 0;
  }
};

export const labelToString = (value: number): string => {
  switch (value) {
    case Label.Update:
      return LabelStrings.Update;
    case Label.Blog:
      return LabelStrings.Blog;
    case Label.Feature:
      return LabelStrings.Feature;
    case Label.Release:
      return LabelStrings.Release;
    default:
      return "";
  }
};

export const emptyUpdate = (): IUpdate => ({
  id: "",
  body: emptyUpdateBody(),
});

export const emptyNewsArray = (): [IUpdate] => [emptyUpdate()];
