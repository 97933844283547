import { InnerRoutes } from "../NavigationItems";

export const UpdatesActions = {
    News: "",
    Create: "add",
    Edit: "edit",
    Delete: "delete",
};

const prefix = InnerRoutes.ManageUpdates;

export const InnerUpdatesPaths = {
    UpdatesPrefix: prefix,
    Updates: `${prefix}`,
    Create: `${prefix}/${UpdatesActions.Create}`,
    Delete: `${prefix}/${UpdatesActions.Delete}/:id`,
    Edit: `${prefix}/${UpdatesActions.Edit}/:id`,
};

export const UpdatesPaths = {
    NewsPrefix: prefix,
    News: `${prefix}`,
    Create: `${prefix}/${UpdatesActions.Create}`,
    Delete: `${prefix}/${UpdatesActions.Delete}`,
    Edit: `${prefix}/${UpdatesActions.Edit}`,
};