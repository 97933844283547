import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import { IUpdateBody } from "../../../../common/dtos/NewsDto";
import { emptyUpdateBody } from "../../factories/NewsFactory";
import { BackwardNavigation } from "./BackwardNavigation/BackwardNavigation";
import { RenderUpdate } from "./RenderUpdate/RenderUpdate";
import { UpdateActions } from "./UpdateAction/UpdateAction";
import { UpdatesApiPaths } from "../../../../common/ApiRoutes/ApiRoutes";
import { UpdateLivePreview } from "../UpdatePreview/UpdateLivePreview";

import "./ModifyUpdate.sass";

interface IModifyUpdateProps {
  id?: string;
}

export const ModifyUpdate: React.FC<IModifyUpdateProps> = (props) => {
  const [temporaryUpdateId, setTemporaryId] = useState(uuidv4());

  let { id } = useParams<{ id: string | undefined }>();
  const [timeout, setTimeoutValue] = useState<number>();
  const [updateBody, setUpdateBody] = useState(emptyUpdateBody());
  const [refresh, setRefresh] = useState<boolean>(false);
  const [previewRefresh, setPreviewRefresh] = useState(false);
  const [isTempUpdateCreated, setCreated] = useState(false);
  const [isUpdateLoad, setUpdateLoad] = useState(false);

  useEffect(() => {
    const getUpdate = async () => {
      const url = `${UpdatesApiPaths.Single}/${id}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const update = (await response.json()) as IUpdateBody;
          setUpdateBody(update);
          await createTemporaryUpdate(update);
          setUpdateLoad(true);
        } else {
          throw new Error("Cannot load update");
        }
      } catch (e) {
        console.log("Unable to modify update");
        console.log(e);
      }
    };

    const createTemporaryUpdate = async (updateBody?: IUpdateBody) => {
      try {
        if (updateBody === undefined) {
          updateBody = emptyUpdateBody();
        }
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updateBody),
        };
        const response = await fetch(
          `${UpdatesApiPaths.CreateTemporaryUpdate}?id=${temporaryUpdateId}`,
          requestOptions
        );
        if (response.ok) {
          setTemporaryId(temporaryUpdateId);
          setRefresh(true);
          setCreated(true);
        } else {
          throw new Error("Cannot create temporary update");
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (id === undefined) {
      createTemporaryUpdate();
    } else {
      getUpdate();
    }
  }, [id, temporaryUpdateId]);

  useEffect(() => {
    return () => {
      const deleteTemporaryUpdate = async () => {
        try {
          const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          };
          const response = await fetch(
            `${UpdatesApiPaths.DeleteTemporaryUpdate}?id=${temporaryUpdateId}`,
            requestOptions
          );
          if (!response.ok) {
            throw new Error("Unable to delete temporary update");
          }
        } catch (e) {
          console.error(e);
        }
      };
      deleteTemporaryUpdate();
    };
  }, [temporaryUpdateId]);

  useEffect(() => {
    if (previewRefresh) {
      setPreviewRefresh(false);
      window.clearTimeout(timeout);
      setTimeoutValue(
        window.setTimeout(() => {
          setRefresh(true);
        }, 1500)
      );
    }
  }, [previewRefresh, timeout]);

  return (
    <React.Fragment>
      <Col xs={10} sm={10} md={8} lg={9} xl={9}>
        <Row className=" justify-content-between modify-update__top-navigation">
          <BackwardNavigation />
        </Row>

        {id === undefined ? (
          <RenderUpdate
            update={updateBody}
            setUpdate={setUpdateBody}
            setRefresh={setPreviewRefresh}
            isEdit={false}
          />
        ) : (
          isUpdateLoad && (
            <RenderUpdate
              update={updateBody}
              setUpdate={setUpdateBody}
              setRefresh={setPreviewRefresh}
              isEdit={true}
            />
          )
        )}
      </Col>
      <Col md={3} lg={3} xl={3} className={"preview d-flex flex-column"}>
        {isTempUpdateCreated ? (
          <UpdateLivePreview
            id={temporaryUpdateId}
            updateBody={updateBody}
            className={"preview__container"}
            refresh={refresh}
            setRefresh={setRefresh}
            previewActions={<UpdateActions update={updateBody} id={id} />}
          />
        ) : (
          ""
        )}
      </Col>
    </React.Fragment>
  );
};
