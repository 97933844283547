import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Redirect } from "react-router";
import { ScaleLoader } from "react-spinners";

import { imageSource } from "../../../common/ApiRoutes/ContentRoutes";
import { AuthPath } from "../../../common/ApiRoutes/ApiRoutes";
import { AuthorizationButtons } from "./AuthorizationButtons/AuthorizationButtons";
import { InnerRoutes } from "../NavigationItems";

import "./Login.sass";

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useState(() => {
    const checkAuthorize = async () => {
      try {
        const response = await fetch(AuthPath.GetUserState);
        if (response.ok) {
          const data = (await response.json()) as {
            auth: boolean;
            name: string;
          };
          if (data.auth) {
            redirectToMainContent();
          }
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    checkAuthorize();
  });
  const redirectToMainContent = () => {
    return <Redirect push to={InnerRoutes.Dashboard} />;
  };

  return loading ? (
    <div className="loader">
      <ScaleLoader color={"#0E9292"} loading={loading} />
    </div>
  ) : (
    <React.Fragment>
      <Container fluid>
        <Row className={"login flex-row-reverse"}>
          <Col
            className={
              "info-container p-5 d-flex justify-content-center flex-column align-items-center"
            }
          >
            <div className="triangle top d-none d-lg-block d-xl-block"></div>
            <Row className={"info-container__text"}>
              {"Easy solution to keep your users informed"}
            </Row>

            <Row className={"info-container__image"}>
              <Image src={imageSource + "/man-with-notes.webp"} />
            </Row>
            <div className="triangle bottom-right d-none d-lg-block d-xl-block"></div>
            <div className="triangle bottom-left d-none d-lg-block d-xl-block"></div>
          </Col>
          <Col
            className={
              "login-container d-flex justify-content-center flex-column align-items-center"
            }
          >
            <Col
              className={
                "p-5 d-flex justify-content-center flex-column align-items-center"
              }
            >
              <Row className={"login-container__logo"}>
                <Image src={imageSource + "/login/news-and-stuff.webp"} />
              </Row>

              <Row className={"login-container__welcome"}>
                {"Welcome to News&Stuff"}
              </Row>

              <Row className={"login-container__login-text"}>
                {"Sign in with"}
              </Row>

              <Row className={"login-container__authorization"}>
                <AuthorizationButtons />
              </Row>
            </Col>
            <Row className={"login-container__footer pb-5"}>
              {"© 2021 All rights reserved - 7pace. Made in Germany."}
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
