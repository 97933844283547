import React, { createRef, useEffect, useState } from "react";
import { UpdatesApiPaths } from "../../../../../../common/ApiRoutes/ApiRoutes";

import "./ImageUploader.sass";

export type ImageUploaderProps = {
  onImageMarkdownStringGenerated: (imageMarkdownString: string) => void;
};

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageMarkdownStringGenerated,
}) => {
  const [imageMarkdownString, setImageMarkdownString] = useState<string | null>(
    null
  );

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append("Image", file);

    const response = await fetch(UpdatesApiPaths.UploadImage, {
      method: "POST",
      body: formData,
    });
    const url = await response.text();

    return url;
  };

  const convertToMarkdownImage = (url: string, alt: string) => {
    return `![${alt}](${url})`;
  };

  const imageUploadRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const imageUploadElement = imageUploadRef.current;

    if (!imageUploadElement) {
      return;
    }

    const handleChangeEvent = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files?.item(0);

      if (!file) {
        return;
      }

      const url = await uploadImage(file);

      const markdownString = convertToMarkdownImage(url, file.name);

      onImageMarkdownStringGenerated(markdownString);
      navigator.clipboard.writeText(markdownString);
      setImageMarkdownString(markdownString);
    };

    imageUploadElement.addEventListener("change", handleChangeEvent);

    return () => {
      if (!imageUploadElement) {
        return;
      }

      imageUploadElement.removeEventListener("change", handleChangeEvent);
    };
  }, [imageUploadRef, onImageMarkdownStringGenerated]);

  return (
    <div>
      <div>
        <span>Add image</span>
      </div>
      <div>
        <label className="image-uploader__label" htmlFor="image-uploader">
          Click to choose an image to add
        </label>
        <input
          className="image-uploader__input"
          ref={imageUploadRef}
          id="image-uploader"
          type="file"
          accept="image/*"
          multiple={false}
        />

        {imageMarkdownString && (
          <>
            <span className="image-uploader__notice">
              Copied the below Image URL to clipboard:
            </span>
            <code>
              <pre>{imageMarkdownString}</pre>
            </code>
          </>
        )}
      </div>
    </div>
  );
};
