import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

import { FetchingStatus } from "../../../../StateManagement/Features/FetchingStatus";
import { useAppSelector } from "../../../../StateManagement/Hooks";
import replaceParameterValue from "../../CustomFunctions/ReplaceParameterValue";
import { productIdParam } from "../../NavigationItems";
import { UpdatesPaths } from "../UpdatesConstants";

import "./ActionsWithUpdate.sass";

interface IActionsWithUpdateProps {
  withText?: boolean;
  className?: string;
  updateId: string;
}

export const ActionsWithUpdate: React.FC<IActionsWithUpdateProps> = ({
  className,
  updateId,
  withText,
}) => {
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchingStatus =
      currentUserProductsFetchingStatus === FetchingStatus.NotStarted ||
      currentUserProductsFetchingStatus === FetchingStatus.Loading
        ? true
        : false;
    setIsLoading(fetchingStatus);
  }, [currentUserProductsFetchingStatus]);

  const actionsWithText = (
    <div className={" d-flex flex-row justify-content"}>
      <Link
        className={className}
        to={`${replaceParameterValue(
          UpdatesPaths.Edit,
          productIdParam,
          `/${currentProduct.productId!}`
        )}/${updateId}`}
      >
        <Button variant="light d-flex flex-row action__button">
          <div className={`action edit`}></div>
          <div className={`action__text`}>Edit</div>
        </Button>
      </Link>
      <Link
        className={className}
        to={`${replaceParameterValue(
          UpdatesPaths.Delete,
          productIdParam,
          `/${currentProduct.productId!}`
        )}/${updateId}`}
      >
        <Button variant="light d-flex flex-row action__button">
          <div className={`action delete`}></div>
          <div className={`action__text`}>Delete</div>
        </Button>
      </Link>
    </div>
  );

  const actions = (
    <React.Fragment>
      <Link
        to={`${replaceParameterValue(
          UpdatesPaths.Edit,
          productIdParam,
          `/${currentProduct.productId!}`
        )}/${updateId}`}
      >
        <Button variant="link" className={`${className} action edit`}></Button>
      </Link>
      <Link
        to={`${replaceParameterValue(
          UpdatesPaths.Delete,
          productIdParam,
          `/${currentProduct.productId!}`
        )}/${updateId}`}
      >
        <Button
          variant="link"
          className={`${className} action delete`}
        ></Button>
      </Link>
    </React.Fragment>
  );
  if (isLoading) {
    return (
      <React.Fragment>
        <ScaleLoader color={"#0E9292"}></ScaleLoader>
      </React.Fragment>
    );
  } else {
    return withText ? actionsWithText : actions;
  }
};
