import React from "react";
import { useHistory } from "react-router";

import useRefresh from "../../../CustomFunctions/CustomRefresh";
import { IUpdateBody } from "../../../../../common/dtos/NewsDto";
import { productIdParam } from "../../../NavigationItems";
import { dashboardStorageKeys } from "../../../StorageKeys";
import { saveUpdate } from "../../SaveUpdate";
import { UpdatesPaths } from "../../UpdatesConstants";
import { useAppSelector } from "../../../../../StateManagement/Hooks";
import replaceParameterValue from "../../../CustomFunctions/ReplaceParameterValue";

import "./UpdateAction.sass";

interface IUpdateActions {
  update: IUpdateBody;
  id?: string;
}

export const UpdateActions: React.FC<IUpdateActions> = ({ update, id }) => {
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const refresh = useRefresh(
    useHistory(),
    replaceParameterValue(
      UpdatesPaths.News,
      productIdParam,
      `/${currentProduct.productId!}`
    ),
    replaceParameterValue(
      UpdatesPaths.News,
      productIdParam,
      `/${currentProduct.productId!}`
    )
  );

  const refreshPage = (response: boolean) => {
    if (response) {
      refresh();
    } else {
      global.alert("Couldn't refresh page");
    }
  };

  const saveChanges = async () => {
    const response =
      id === undefined
        ? await saveUpdate(update)
        : await saveUpdate(update, id);
    window.localStorage.removeItem(dashboardStorageKeys.update);
    refreshPage(response.status);
  };

  return (
    <div className={"change-update-state"}>
      {id === undefined ? (
        <button onClick={saveChanges} className={"publish-button"}>
          <div className="publish-button__image"></div>
          <span>Publish</span>
        </button>
      ) : (
        <button onClick={saveChanges} className={"save-button"}>
          <div className="save-button__image"></div>
          <span>Save</span>
        </button>
      )}
    </div>
  );
};
