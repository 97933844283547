import React, { useEffect } from "react";
import { UpdatesApiPaths } from "../../../../common/ApiRoutes/ApiRoutes";
import { IUpdateBody } from "../../../../common/dtos/NewsDto";

import { createPlaceholders } from "./UpdatePreview";

import "./UpdatePreview.sass";

interface IUpdateLivePreviewProps {
  id: string;
  updateBody: IUpdateBody;
  className?: string;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
  previewActions?: React.ReactNode;
}

export const UpdateLivePreview: React.FC<IUpdateLivePreviewProps> = ({
  id,
  updateBody,
  className,
  refresh,
  setRefresh,
  previewActions,
}) => {
  useEffect(() => {
    const modifyUpdate = async () => {
      try {
        const requestOptions = {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updateBody),
        };
        const response = await fetch(
          `${UpdatesApiPaths.ModifyTemporaryUpdate}/?id=${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Couldn't modify update");
        }
      } catch (e) {
        console.error(e);
      }
    };

    const resizeIframe = (iframe: HTMLIFrameElement) => {
      const content = iframe.contentDocument?.getElementsByClassName("content");
      if (content?.length !== 0) {
        iframe.height = "";
        let height = 0;
        if (content?.item(0) !== undefined) {
          height = content!.item(0)!.scrollHeight + 80;
        }

        iframe.height = height + "px";
      }
    };

    if (refresh) {
      modifyUpdate();
      setRefresh(false);

      const iframe = document.getElementById(
        "preview-iframe"
      ) as HTMLIFrameElement;
      iframe?.contentWindow?.postMessage(id, window.location.origin);
      window.addEventListener(
        "message",
        () => {
          resizeIframe(iframe);
        },
        false
      );
    }
  }, [id, refresh, setRefresh, updateBody]);

  return (
    <React.Fragment>
      <div
        className={`${className} pt-5 update-preview__actions d-flex align-items-center ${refresh}`}
      >
        <div className="update-preview__actions__text">Preview</div>
        <div className="update-preview__actions__container">
          {previewActions}
        </div>
      </div>

      <div className={`update-preview`}>
        <div className="update-preview__item">
          <iframe
            id={"preview-iframe"}
            className={`update-preview__iframe`}
            title={"Update preview"}
            src={`${window.location.origin}/widget/live-preview?id=${id}`}
            onLoad={() => {
              const iframe = document.getElementById(
                "preview-iframe"
              ) as HTMLIFrameElement;
              const content =
                iframe.contentDocument?.getElementsByClassName("content");
              if (content?.length !== 0) {
                iframe.height = "";
                let height = 0;
                if (content?.item(0) !== undefined) {
                  height = content!.item(0)!.scrollHeight + 80;
                }

                iframe.height = height + "px";
              }
            }}
          ></iframe>
        </div>
        {createPlaceholders()}
      </div>
    </React.Fragment>
  );
};
