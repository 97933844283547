import React from "react";
import { Route, Switch } from "react-router";
import { Provider } from "react-redux";

import { store } from "./StateManagement/AppStore";
import { ApplicationPaths } from "./features/Management/api-authorization/ApiAuthorizationConstants";

import "./common/css/custom.css";
import "semantic-ui-css/semantic.min.css";
import "./common/css/base.sass";
import "./common/css/BootstrapCustomStyles.sass";
import { MANAGEMENT_BASE_PATH } from "./constants";
import { ProductNews } from "./features/ProductNews/ProductNews";
import { Login } from "./features/Management/Login/Login";
import { MainLayout } from "./features/Management/MainLayout/MainLayout";

export const App: React.FC = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Switch>
          <Route exact path={ApplicationPaths.Login} component={Login} />
          <Route
            path={`${MANAGEMENT_BASE_PATH}/:productId/*`}
            component={MainLayout}
          />
          <Route path="/:productUrlPath" component={ProductNews} />
        </Switch>
      </Provider>
    </React.Fragment>
  );
};
