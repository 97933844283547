import { useEffect } from "react";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";

export default function useRefresh(
  history: any,
  path: string,
  resetRoute: string = ApplicationPaths.Login
) {
  let handler: any;

  const refresh = () => {
    history.push(resetRoute);

    handler = setTimeout(() => history.push(path), 10);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
}
