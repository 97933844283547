interface ErrorResponse {
  productNameExists: boolean;
  productPathExists: boolean;
}

export const getErrorResponse = async (
  response: Response
): Promise<ErrorResponse | null> => {
  try {
    return await response.json();
  } catch {
    return null;
  }
};
