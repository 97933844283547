import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Image, Table } from "react-bootstrap";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

import { UpdatesApiPaths } from "../../../../../common/ApiRoutes/ApiRoutes";
import { IUpdate } from "../../../../../common/dtos/NewsDto";
import { ActionsWithUpdate } from "../../ActionsWithUpdate/ActionsWithUpdate";
import { imageSource } from "../../../../../common/ApiRoutes/ContentRoutes";
import { Link } from "react-router-dom";
import { UpdatesPaths } from "../../UpdatesConstants";
import { CustomTooltip } from "./TextTooltip/CustomTooltip";
import { IPreviewData } from "../../UpdatePreview/UpdatePreview";
import { emptyUpdate } from "../../../factories/NewsFactory";
import { useAppSelector } from "../../../../../StateManagement/Hooks";

import "./UpdatesTable.sass";

interface IUpdatesTableProps {
  setPreviewData: (data: IPreviewData) => void;
}

interface ILabel {
  id: string;
  title: string;
  titleImage: string;
  textColor: string;
  backgroundColor: string;
}

export const UpdatesTable: React.FC<IUpdatesTableProps> = ({
  setPreviewData,
}) => {
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);

  const [updates, setUpdates] = useState<[IUpdate]>();
  const [activeRowId, setActiveRowId] = useState("");
  const [labels, setLabels] = useState<[ILabel]>();
  const [isLoading, setLoading] = useState(true);
  const currentProduct = useAppSelector((state) => state.currentProduct);

  useEffect(() => {
    const getLabels = async () => {
      try {
        const response = await fetch(UpdatesApiPaths.GetLabels);
        if (response.ok) {
          const data = (await response.json()) as [ILabel];
          if (data.length > 0) {
            setLabels(data);
          }
        } else {
          throw new Error("Unable to retrieve labels");
        }
      } catch (e) {
        console.error(e);
      }
    };
    getLabels();
  }, [currentProduct.productId]);

  useEffect(() => {
    const getUpdates = async () => {
      try {
        const response = await fetch(UpdatesApiPaths.All);
        if (response.ok) {
          const data = (await response.json()) as [IUpdate];
          setUpdates(data);
          setLoading(false);
        } else {
          throw new Error("Unable to retrieve updates");
        }
      } catch (e) {
        console.error(e);
      }
    };
    getUpdates();
  }, [currentProduct.productId]);

  const emptyUpdatesList = (loading?: boolean) => {
    return (
      <React.Fragment>
        <tbody
          className={`empty-list__body ${
            loading ? "gray" : ""
          } d-flex justify-content-center align-items-center`}
        >
          <tr className={"empty-list__row d-flex"}>
            <td
              className={
                "empty-list__content d-flex flex-column justify-content-center align-items-center col-10"
              }
            >
              {loading ? (
                <ScaleLoader color={"#0E9292"} />
              ) : (
                <React.Fragment>
                  <Image
                    className={"empty-list__image"}
                    src={`${imageSource}/dashboard/man-with-notes-blue-bg.png`}
                  ></Image>
                  <div className="empty-list__text d-flex flex-column justify-content-center align-items-center">
                    There are no updates in the list
                    <div
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                    >
                      Start
                      <Link
                        className={
                          "empty-list__link ps-2 d-flex flex-column justify-content-center align-items-center"
                        }
                        to={UpdatesPaths.Create}
                      >
                        {"adding"}
                        <div className="empty-list__link__border"></div>
                      </Link>
                      !
                    </div>
                  </div>
                </React.Fragment>
              )}
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  };

  const tooltipTitle = (title: string): React.ReactNode => {
    const indexOfSpace = title.substring(0, 42).lastIndexOf(" ");

    let normalizingTitle = title.substring(0, 33);
    if (indexOfSpace > -1) {
      normalizingTitle = title.substring(0, indexOfSpace);
    }

    return (
      <React.Fragment>
        <CustomTooltip fullDescription={title}>
          {normalizingTitle + "..."}
        </CustomTooltip>
      </React.Fragment>
    );
  };

  const outsideClickHandler = (e: any): void => {
    if (
      e.target.className.includes("update ") ||
      e.target.className.includes("update_") ||
      e.target.className.includes("preview") ||
      e.target.className.includes("table") ||
      e.target.className.includes("tooltip")
    ) {
      return;
    }
    setActiveRowId("");
  };

  const closePreview = (event: any) => {
    if (event.key === "Escape") {
      setActiveRowId("");
    }
  };

  useEffect(() => {
    if (activeRowId === "") {
      window.removeEventListener("click", outsideClickHandler, false);
      document.removeEventListener("keydown", closePreview);
      setPreviewData({
        update: emptyUpdate(),
        active: false,
      });
    } else {
      window.addEventListener("click", outsideClickHandler, false);
      document.addEventListener("keydown", closePreview);
    }
  }, [activeRowId, setPreviewData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("click", outsideClickHandler, false);
      document.removeEventListener("keydown", closePreview);
    };
  });

  const onUpdateClick = (value: IUpdate) => {
    setPreviewData({
      update: value,
      active: true,
    });
    setActiveRowId(value.id);
  };

  const updatesList = (
    <React.Fragment>
      <tbody>
        {updates?.map((update) => (
          <tr
            className={`update ${
              update.id === activeRowId ? "active" : ""
            } d-flex`}
            key={update.id}
            onClick={() => onUpdateClick(update)}
          >
            <td
              className={
                "update__item__label-container d-flex justify-content-center align-items-center col-2"
              }
            >
              <div
                className="update__item__label d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: labels?.find(
                    (item) => item.id === update.body.label
                  )?.backgroundColor,
                  color: labels?.find((item) => item.id === update.body.label)
                    ?.textColor,
                }}
              >
                {labels?.find((item) => item.id === update.body.label)?.title}
              </div>
            </td>
            <td
              className={
                "update__item bold d-flex justify-content-start align-items-center col-4"
              }
            >
              {update.body.title.length > 40
                ? tooltipTitle(update.body.title)
                : update.body.title}
            </td>
            <td
              className={
                "update__item d-flex justify-content-start align-items-center col-2"
              }
            >
              {update.body.version}
            </td>
            <td
              className={
                "update__item d-flex justify-content-start align-items-center col-3"
              }
            >
              {dayjs.utc(update.body.date).local().format("LL")}
            </td>
            <td
              className={
                "update__item__actions-container d-flex justify-content-center align-items-center col-1"
              }
            >
              <ActionsWithUpdate
                className={"update__actions"}
                updateId={update.id}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Table
        className={`updates-table ${
          activeRowId === "" ? "" : "element-selected"
        } align-middle`}
        responsive
        bordered
      >
        <thead>
          <tr className={"update d-flex head"}>
            <td className={"update__head-item col-2 align-top"}>Type</td>
            <td className={"update__head-item col-4 align-top"}>Title</td>
            <td className={"update__head-item col-2 align-top"}>Version</td>
            <td className={"update__head-item col-3 align-top"}>
              Release Date
            </td>
            <td className={"update__head-item col-1 align-top"}>Actions</td>
          </tr>
        </thead>
        {isLoading
          ? emptyUpdatesList(isLoading)
          : updates!.length > 0
          ? updatesList
          : emptyUpdatesList()}
      </Table>
    </React.Fragment>
  );
};
