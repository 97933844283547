import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

import { useAppDispatch, useAppSelector } from "../../../StateManagement/Hooks";
import { ProductPaths } from "../../../common/ApiRoutes/ApiRoutes";
import setProductIdParam from "../CustomFunctions/SetProductIdParam";
import { ProductDTO } from "../../../common/dtos/ProductDTO";

import "./ProductSelector.sass";
import { FetchingStatus } from "../../../StateManagement/Features/FetchingStatus";
import { setCurrentProduct } from "../../../StateManagement/Features/CurrentProduct/CurrentProductSlice";
import {
  fetchCurrentUser,
  setCurrentUser,
} from "../../../StateManagement/Features/CurrentUser/CurrentUserSlice";

interface IProductSelectorProps {}

export const ProductSelector: React.FC<IProductSelectorProps> = () => {
  const currentUserProductsList = useAppSelector(
    (state) => state.userProducts.userProducts
  );
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUserFetchingStatus = useAppSelector(
    (state) => state.currentUser.fetchingStatus
  );
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const fetchingStatus =
    currentUserFetchingStatus === FetchingStatus.Loading ||
    currentUserProductsFetchingStatus === FetchingStatus.Loading
      ? true
      : false;
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(fetchingStatus);

  const onProductChange = (product: ProductDTO) => {
    changeProduct(product);
  };

  const changeProduct = async (product: ProductDTO) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        ProductPaths.ChangeProduct + product.productId
      );
      if (response.ok) {
        dispatch(setCurrentProduct(product));
        dispatch(
          setCurrentUser({
            ...currentUser,
            role: product?.currentUserRole,
            productId: product?.productId,
          })
        );
      }
    } catch (e) {
      console.error("Unable to change product");
      console.error(e);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentProduct.productId !== undefined) {
      setProductIdParam(currentProduct.productId);
    }
  }, [currentProduct.productId]);

  if (isLoading) {
    return (
      <React.Fragment>
        <div className="product-selector">
          <ScaleLoader color={"#0E9292"}></ScaleLoader>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="product-selector">
          You are now viewing:
          <span className="selected-product">{currentProduct.productName}</span>
          <a
            href="#/"
            className="change-product"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
          >
            Change
          </a>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Choose a product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="products-row">
              <Col xl={11} lg={11} md={10} sm={10} xs={10}>
                <div className="d-grid gap-3">
                  {currentUserProductsList?.map((product) => {
                    return (
                      <Button
                        onClick={() => {
                          onProductChange(product);
                        }}
                        disabled={
                          product.productId === currentProduct.productId
                            ? true
                            : false
                        }
                        className="btn-product"
                        key={product.productId}
                        variant="secondary"
                        size="lg"
                      >
                        {product.productName}
                      </Button>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
};
