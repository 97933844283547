import React from "react";
import { Dropdown } from "react-bootstrap";

interface ICustomBootstrapDropdownItem {
  label: string;
  labelId: string;
  onChange: (v: undefined, label?: string) => void;
}

export const CustomBootstrapDropdownItem: React.FC<ICustomBootstrapDropdownItem> =
  ({ label, labelId, onChange }) => {
    return (
      <Dropdown.Item onClick={() => onChange(undefined, labelId)}>
        {label}
      </Dropdown.Item>
    );
  };
