import React, { Component, Fragment } from "react";
import { Route } from "react-router";

import { DeleteUpdate } from "./DeleteNews";
import { InnerUpdatesPaths } from "./UpdatesConstants";
import { UpdatesList } from "./UpdatesList/UpdatesList";
import { ModifyUpdate } from "./ModifyUpdate/ModifyUpdate";

export default class UpdatesRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Route exact path={InnerUpdatesPaths.Updates} component={UpdatesList} />
        <Route path={InnerUpdatesPaths.Create} component={ModifyUpdate} />
        <Route path={InnerUpdatesPaths.Edit} component={ModifyUpdate} />
        <Route path={InnerUpdatesPaths.Delete} component={DeleteUpdate} />
      </Fragment>
    );
  }
}
