import React from "react";
import { Button, Image } from "react-bootstrap";

import { imageSource } from "../../../../../common/ApiRoutes/ContentRoutes";

import "./MicrosoftAuthorization.sass";

interface IMicrosoftAuthorizationProps {}

export const MicrosoftAuthorization: React.FC<
  IMicrosoftAuthorizationProps
> = () => {
  const onClick = () => {
    const redirectUrl = `${window.location.origin}/microsoft`;

    window.location.replace(redirectUrl);
  };
  return (
    <React.Fragment>
      <Button
        className={"microsoft"}
        variant="outline-primary"
        onClick={onClick}
      >
        <Image
          className={"microsoft__logo"}
          src={imageSource + "/login/microsoft-icon.webp"}
        />
        {"Microsoft"}
      </Button>
    </React.Fragment>
  );
};
