import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { UsersApiPath } from "../UsersApiPath";

import "./ActionsWithUser.sass";

interface IActionsWithUserProps {
  className: string;
  userEmail: string;
  productId: string | undefined;
  onDeleteAction?: () => void;
}

export const ActionsWithUser: React.FC<IActionsWithUserProps> = ({
  className,
  userEmail,
  productId,
  onDeleteAction,
}) => {
  const [show, setShow] = useState(false);

  const deleteUser = async () => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      const productIdParameter = `&productId=${productId}`;
      const response = await fetch(UsersApiPath.DeleteUser + userEmail + productIdParameter, requestOptions);
      if (response.ok) {
        onDeleteAction!();
      } else {
        throw new Error("Unable to delete user");
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <React.Fragment>
      <div
        className={`remove-user ${className}`}
        onClick={() => setShow(true)}
      ></div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete the user?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="light"
            className={"delete-user"}
            onClick={() => {
              deleteUser();
              setShow(false);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
