import { immerable } from "immer";

export class ProductDTO {
  [immerable] = true;
  productName?: string;
  productPath?: string;
  productId?: string;
  currentUserRole?: string;
  userCount?: number;
  invitationToken?: string;
}
