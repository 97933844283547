import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import { AuthPath } from "../../../../common/ApiRoutes/ApiRoutes";
import { navigationMenuItems } from "../../NavigationItems";
import { ProductSelector } from "../../ProductSelector/ProductSelector";

import "./TopNavigation.sass";

interface ITopNavigationProps {
  setSectionName: (value: string) => void;
  sectionName: string;
}

export const TopNavigation: React.FC<ITopNavigationProps> = ({
  setSectionName,
  sectionName,
}) => {
  const onLogout = async () => {
    try {
      const response = await fetch(AuthPath.Logout);
      if (response.ok) {
        const success = (await response.json()) as boolean;
        if (success) {
          window.sessionStorage.clear();
          setSectionName(navigationMenuItems[0].sectionName);
          const redirectUrl = `${window.location.origin}/management/auth/login`;

          window.location.replace(redirectUrl);
        } else {
          throw new Error("Internal server error");
        }
      } else {
        throw new Error("Cannot logout. Bad response");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Row className={"top-navigation justify-content-between"}>
      <Col
        xs={8}
        sm={8}
        md={10}
        lg={10}
        xl={10}
        className={"top-navigation__action-name"}
      >
        <ProductSelector />
        <span>{sectionName}</span>
      </Col>
      <Col xs={4} sm={4} md={2} lg={1} xl={1} className="me-xl-5 me-lg-5">
        <Button
          variant="outline-light"
          className={"top-navigation__logout"}
          onClick={onLogout}
        >
          <div className="top-navigation__logout__icon"></div>
          <div className="top-navigation__logout__text">Logout</div>
        </Button>
      </Col>
    </Row>
  );
};
