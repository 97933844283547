import { configureStore } from "@reduxjs/toolkit";

import { currentProductSlice } from "./Features/CurrentProduct/CurrentProductSlice";
import { currentUserSlice } from "./Features/CurrentUser/CurrentUserSlice";
import { userProductsSlice } from "./Features/CurrentUserProductsList/UserProductsSlice";
export const store = configureStore({
    reducer: {
        userProducts: userProductsSlice.reducer,
        currentUser: currentUserSlice.reducer,
        currentProduct: currentProductSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;