import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../AppStore";
import { setCurrentProduct } from "../CurrentProduct/CurrentProductSlice";
import { FetchingStatus } from "../FetchingStatus";
import { ProductPaths } from "../../../common/ApiRoutes/ApiRoutes";
import { ProductDTO } from "../../../common/dtos/ProductDTO";

export const fetchCurrentUserProducts = createAsyncThunk(
  "users/getCurrentUserProducts",
  async (obj: FetchUserProductsParams, thunkAPI) => {
    try {
      let currState = thunkAPI.getState() as RootState;
      if (currState.userProducts.fetchingStatus === FetchingStatus.Loading) {
        return;
      }
      thunkAPI.dispatch(setFetchingStatus(FetchingStatus.Loading));
      const response = await fetch(
        ProductPaths.GetProductsForUser + obj.userEmail
      );
      if (response.ok) {
        const data = (await response.json()) as ProductDTO[];
        thunkAPI.dispatch(setProducts(data));
        let currentProduct = data.find(function (product) {
          return product.productId === obj.productId;
        });
        if (currentProduct) {
          thunkAPI.dispatch(setCurrentProduct(currentProduct));
          return;
        }
        if (currState.userProducts) {
          thunkAPI.dispatch(
            setCurrentProduct(currState.userProducts.userProducts[0])
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

interface FetchUserProductsParams {
  userEmail?: string;
  productId?: string;
}

interface CurrentUserProductsState {
  userProducts: ProductDTO[];
  fetchingStatus: FetchingStatus;
}

const initialState: CurrentUserProductsState = {
  userProducts: [] as ProductDTO[],
  fetchingStatus: FetchingStatus.NotStarted,
};

export const userProductsSlice = createSlice({
  name: "userProducts",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<ProductDTO>) => {
      state.userProducts.push(action.payload);
    },
    removeProduct: (state, action: PayloadAction<string | undefined>) => {
      const productIndex = state.userProducts.findIndex(
        (obj) => obj.productId === action.payload
      );
      if (productIndex !== -1) {
        state.userProducts = state.userProducts.splice(productIndex, 1);
      }
    },
    setProducts: (state, action: PayloadAction<ProductDTO[]>) => {
      if (action) {
        Object.assign(state.userProducts, action.payload);
      }
    },
    editProduct: (state, action: PayloadAction<ProductDTO>) => {
      var productIndex = state.userProducts
        .map((e) => e.productId)
        .indexOf(action.payload.productId);
      if (productIndex !== -1) {
        state.userProducts[productIndex] = action.payload;
      }
    },
    setFetchingStatus: (state, action: PayloadAction<FetchingStatus>) => {
      Object.assign(state.fetchingStatus, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserProducts.fulfilled, (state, action) => {
      state.fetchingStatus = FetchingStatus.Succeeded;
    });
    builder.addCase(fetchCurrentUserProducts.rejected, (state, action) => {
      state.fetchingStatus = FetchingStatus.Failed;
    });
  },
});

export const {
  addProduct,
  removeProduct,
  setProducts,
  editProduct,
  setFetchingStatus,
} = userProductsSlice.actions;

export const selectUserProducts = (state: RootState) => state;
