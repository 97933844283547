export interface IUpdate {
  id: string;
  body: IUpdateBody;
}
export enum Label {
  Update = 1,
  Blog = 2,
  Feature = 3,
  Release = 4,
}
export const LabelStrings = {
  Update: "Update",
  Blog: "Blog",
  Feature: "Feature",
  Release: "Release",
};
export interface IUpdateBody {
  label: string;
  title: string;
  version: string;
  date: string;
  body: string;
}
