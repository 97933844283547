import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { ActionsWithUser } from "../ActionsWithUser/ActionsWithUser";
import { UsersApiPath } from "../UsersApiPath";
import { UserRole } from "../UserRole/UserRole";
import { RoleDTO } from "../../../../common/dtos/RoleDTO";
import { ScaleLoader } from "react-spinners";
import { Roles } from "../../Enums/Roles";
import { useAppSelector } from "../../../../StateManagement/Hooks";

import "./UsersTable.sass";

interface IUsersTableProps {
  resetUsers: any;
}

export interface IUserInfoCallback {
  id: string;
  email: string;
  userName: string;
  role: string;
}

export const UsersTable: React.FC<IUsersTableProps> = ({ resetUsers }) => {
  const [usersList, setUsersList] = useState<[IUserInfoCallback]>();
  const [rolesList, setRolesList] = useState<[RoleDTO]>();
  const [isUserDelete, setUserDeleteAction] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const currentProduct = useAppSelector((state) => state.currentProduct);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(UsersApiPath.GetUsers);
        if (!response.ok) {
          throw new Error("Unable to fetch users");
        }
        const data = (await response.json()) as [IUserInfoCallback];
        setUsersList(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    resetUsers.current = getUserInfo;

    getUserInfo();
    setUserDeleteAction(false);
  }, [isUserDelete, currentProduct.productId, resetUsers]);

  useEffect(() => {
    async function getRoles() {
      try {
        const response = await fetch(UsersApiPath.GetRoles);
        if (response.ok) {
          var data = (await response.json()) as [RoleDTO];
          setRolesList(data);
        } else {
          throw new Error("Unable to fetch roles");
        }
      } catch (e) {
        console.error(e);
      }
    }

    getRoles();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        {" "}
        <ScaleLoader color={"#0E9292"}></ScaleLoader>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Table className={"user-table"} responsive bordered>
          <thead>
            <tr className={"user head d-flex"}>
              <td className={"update__head-item col-5 align-top"}>Email</td>
              <td className={"update__head-item col-3 align-top"}>Name</td>
              <td className={"update__head-item col-2 align-top"}>Role</td>
              <td className={"update__head-item col-2 align-top"}>Actions</td>
            </tr>
          </thead>
          <tbody>
            {usersList?.map((update) => (
              <tr className={"user d-flex"} key={update.id}>
                <td
                  className={
                    "user__item bold d-flex justify-content-start align-items-center col-5"
                  }
                >
                  {update.email}
                </td>
                <td
                  className={
                    "user__item d-flex justify-content-start align-items-center col-3"
                  }
                >
                  {update.userName}
                </td>
                <td
                  className={
                    "user__item d-flex justify-content-start align-items-center col-2"
                  }
                >
                  <UserRole
                    rolesList={rolesList}
                    currentUserEmail={currentUser.email}
                    currentUserRole={currentUser.role}
                    user={update}
                    usersList={usersList}
                    resetUsersAction={setUsersList}
                  ></UserRole>
                </td>
                <td
                  className={
                    "user__item d-flex justify-content-start align-items-center col-2"
                  }
                >
                  {update.email !== currentUser.email &&
                  currentUser.role === Roles.Admin ? (
                    <ActionsWithUser
                      className={"user__actions"}
                      onDeleteAction={() => {
                        setUserDeleteAction(true);
                      }}
                      userEmail={update.email}
                      productId={currentProduct.productId}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
};
