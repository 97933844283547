import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

import { UpdatesApiPaths } from "../../../common/ApiRoutes/ApiRoutes";
import { CustomCard } from "./CustomCard/CustomCard";
import { InnerRoutes, productIdParam } from "../NavigationItems";
import { useAppDispatch, useAppSelector } from "../../../StateManagement/Hooks";
import replaceParameterValue from "../CustomFunctions/ReplaceParameterValue";

import "./Dashboard.sass";
import { FetchingStatus } from "../../../StateManagement/Features/FetchingStatus";
import { fetchCurrentUser } from "../../../StateManagement/Features/CurrentUser/CurrentUserSlice";
import { InnerUpdatesPaths } from "../Updates/UpdatesConstants";

interface IDashboardServerData {
  updatesCount: number;
  scheduledUpdates: number;
  recentlyReleased: number;
  views: number;
  lastPublishDate: string;
  error?: string;
  exception?: string;
}

export const Dashboard: React.FC = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const currentProduct = useAppSelector((state) => state.currentProduct);

  const dispatch = useAppDispatch();
  const currentUserFetchingStatus = useAppSelector(
    (state) => state.currentUser.fetchingStatus
  );
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const fetchingStatus =
    currentUserFetchingStatus === FetchingStatus.Loading ||
    currentUserProductsFetchingStatus === FetchingStatus.Loading
      ? true
      : false;

  const [isUserNameLoading, setUserNameLoading] = useState(fetchingStatus);
  const [areCardsLoading, setCardsLoading] = useState(fetchingStatus);

  const [dashboardData, setDashboardData] = useState<IDashboardServerData>();
  const [isDashboardDataLoading, setDashboardDataLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    setUserNameLoading(fetchingStatus);
    setCardsLoading(fetchingStatus);
  }, [fetchingStatus]);

  useEffect(() => {
    const getUpdatesData = async () => {
      try {
        const response = await fetch(UpdatesApiPaths.GetDashboardData);
        if (response.ok) {
          const data = (await response.json()) as IDashboardServerData;
          if (data.lastPublishDate !== "") {
            data.lastPublishDate = dayjs
              .utc(data.lastPublishDate)
              .local()
              .format("LL");
          }
          setDashboardDataLoading(false);
          setDashboardData(data);
        } else {
          const data = (await response.json()) as IDashboardServerData;
          if (data.error) {
            console.error(data.error);
            console.error(data.exception);
            throw new Error("Cannot get dashboard data\n" + data.error);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    getUpdatesData();
  }, [currentProduct.productId]);

  const cards = (
    <React.Fragment>
      <Col className={"navigation-cards mb-2 mb-sm-4"} xs={8} sm={6} lg={3}>
        <CustomCard
          loading={isDashboardDataLoading && areCardsLoading}
          cornerInfo={`${
            dashboardData === undefined
              ? "0  updates"
              : dashboardData.updatesCount === 1
              ? dashboardData.updatesCount + " update"
              : dashboardData.updatesCount + " updates"
          }`}
          cornerAdditionalInfo={`${
            dashboardData === undefined ? "0" : dashboardData.scheduledUpdates
          } updates scheduled`}
          imageLink={"/dashboard/man-with-notes-blue-bg.webp"}
          buttonUrl={replaceParameterValue(
            InnerUpdatesPaths.Create,
            productIdParam,
            `/${currentProduct.productId!}`
          )}
          buttonText={"Add new"}
          additionalInfo={`${
            dashboardData === undefined || dashboardData.recentlyReleased === 0
              ? "No new"
              : dashboardData.recentlyReleased
          } Updates released recently`}
          linkText={"Manage Updates"}
          linkUrl={replaceParameterValue(
            InnerRoutes.ManageUpdates,
            productIdParam,
            `/${currentProduct.productId!}`
          )}
        />
      </Col>
      <Col className={"navigation-cards"} xs={8} sm={6} lg={3}>
        <CustomCard
          loading={isDashboardDataLoading}
          cornerInfo={`${
            dashboardData === undefined
              ? "0"
              : dashboardData.views === 1
              ? dashboardData.views + " view last week"
              : dashboardData.views + " views last week"
          } `}
          imageLink={"/dashboard/woman-with-magnifier-blue-bg.webp"}
          additionalInfo={
            dashboardData === undefined
              ? ""
              : dashboardData.lastPublishDate === ""
              ? "No published updates"
              : `Latest release ${dashboardData.lastPublishDate}`
          }
          //I commented out this line because as of 9/26/2021, the reporting function does not yet exist
          // linkText={"Reporting"}
          // linkUrl={"/reporting"}
        />
      </Col>
    </React.Fragment>
  );

  return (
    <div>
      <Row className={"username"}>
        {isUserNameLoading ? (
          <ScaleLoader color={"#E7F4F4"} />
        ) : (
          `Hello, ${currentUser.name}`
        )}
      </Row>
      <Row className={"d-sm-none d-none d-md-none d-lg-flex"}>{cards}</Row>
      <Row
        className={
          "d-sm-flex d-lg-none d-md-flex d-flex flex-column justify-content-center align-items-center pt-3 pt-sm-3 pt-md-4"
        }
      >
        {cards}
      </Row>
    </div>
  );
};
