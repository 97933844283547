import React from "react";
import { Badge, Table } from "react-bootstrap";

import { ProductDTO } from "../../../../common/dtos/ProductDTO";
import { Roles } from "../../Enums/Roles";
import { ProductAction } from "../ProductActions/ProductAction";
import { ProductActions } from "../ProductActions/ProductActions";

import "./ProductsTable.sass";

interface IProductsTableProps {
  products: ProductDTO[];
  currentProduct: ProductDTO;
  resetProducts: () => void;
}

interface IProductsTableProps {}

export const ProductsTable: React.FC<IProductsTableProps> = ({
  products,
  currentProduct,
  resetProducts,
}) => {
  return (
    <React.Fragment>
      <Table className={"product-table"} responsive bordered>
        <thead>
          <tr className={"product head d-flex"}>
            <td className={"update__head-item col-4 align-top"}>id</td>
            <td className={"update__head-item col-3 align-top"}>Name</td>
            <td className={"update__head-item col-2 align-top"}>URL Path</td>
            <td className={"update__head-item col-1 align-top"}>Users</td>
            <td className={"update__head-item col-2 align-top"}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => (
            <tr className={"product d-flex"} key={product?.productId}>
              <td
                className={
                  "product__item bold d-flex justify-content-start align-items-center col-4"
                }
              >
                {product?.productId}
              </td>
              <td
                className={
                  "product__item d-flex justify-content-start align-items-center col-3"
                }
              >
                {product?.productName}
                {currentProduct.productId === product?.productId ? (
                  <Badge className="pill-selected" bg="success">
                    Current
                  </Badge>
                ) : null}
              </td>
              <td
                className={
                  "product__item d-flex justify-content-start align-items-center col-2"
                }
              >
                {product?.productPath}
              </td>
              <td
                className={
                  "product__item d-flex justify-content-start align-items-center col-1"
                }
              >
                {product?.userCount}
              </td>
              <td
                className={
                  "user__item d-flex justify-content-start align-items-center col-2"
                }
              >
                {product.currentUserRole === Roles.Admin ? (
                  <ProductAction
                    className={"product__actions"}
                    action={ProductActions.Delete}
                    product={product}
                    products={products}
                    resetProducts={() => {
                      resetProducts();
                    }}
                  ></ProductAction>
                ) : null}
                {product.currentUserRole === Roles.Admin ? (
                  <ProductAction
                    className={"product__actions"}
                    action={ProductActions.Edit}
                    product={product}
                    products={products}
                    resetProducts={() => {
                      resetProducts();
                    }}
                  ></ProductAction>
                ) : null}
                {products.length > 1 &&
                product?.userCount &&
                product.userCount > 1 ? (
                  <ProductAction
                    className={"product__actions"}
                    action={ProductActions.Leave}
                    product={product}
                    products={products}
                    resetProducts={() => {
                      resetProducts();
                    }}
                  ></ProductAction>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
