export const WidgetActions = {
  Edit: "edit",
};

const prefix = "/widget";

export const WidgetPaths = {
  WidgetPrefix: `${prefix}`,
  Edit: `${prefix}/${WidgetActions.Edit}`,
};
