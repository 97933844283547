import React from "react";
import styled from "styled-components";

export type ErrorProps = {
  message: string;
};

export const Error: React.FC<ErrorProps> = ({ message }) => {
  return (
    <ErrorStyle>
      <Title>{message}</Title>
    </ErrorStyle>
  );
};

const ErrorStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  font: ${(p) => p.theme.typography.title.l};
`;
