import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { ScaleLoader } from "react-spinners";
import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";

import { NavigationMenu } from "./NavigationMenu/NavigationMenu";
import { TopNavigation } from "./TopNavigation/TopNavigation";
import { imageSource } from "../../../common/ApiRoutes/ContentRoutes";
import { Dashboard } from "../Dashboard/Dashboard";
import { InnerRoutes } from "../NavigationItems";
import { Products } from "../Products/Products";
import { useAppDispatch, useAppSelector } from "../../../StateManagement/Hooks";

import "./MainLayout.sass";
import { FetchingStatus } from "../../../StateManagement/Features/FetchingStatus";
import { fetchCurrentUser } from "../../../StateManagement/Features/CurrentUser/CurrentUserSlice";
import { MANAGEMENT_BASE_PATH } from "../../../constants";
import { Users } from "../Users/Users";
import { InnerUpdatesPaths } from "../Updates/UpdatesConstants";
import UpdatesRoutes from "../Updates/UpdatesRoutes";
import { WidgetPaths } from "../widget/WidgetConstants";
import WidgetRoutes from "../widget/WidgetRoutes";

export const MainLayout: React.FC = () => {
  const [sectionName, setSectionName] = useState(
    window.location.toString().split("/management/")[1].split("/")[1]
  );
  const dispatch = useAppDispatch();
  const currentUserFetchingStatus = useAppSelector(
    (state) => state.currentUser.fetchingStatus
  );
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const fetchingStatus =
    currentUserFetchingStatus === FetchingStatus.Loading ||
    currentUserProductsFetchingStatus === FetchingStatus.Loading
      ? true
      : false;

  const isLoading = useMemo(() => {
    return fetchingStatus;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <ScaleLoader color={"#0E9292"}></ScaleLoader>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Container fluid>
          <Row className={"layout"}>
            <Col xs={12} sm={12} lg={2} className={"navigation__left"}>
              <Row
                className={
                  "layout__logo d-none d-lg-flex justify-content-start align-items-center"
                }
              >
                <Image
                  className={"layout__logo__image"}
                  src={imageSource + "/login/news-and-stuff.png"}
                />
              </Row>
              <Row>
                <NavigationMenu
                  sectionName={sectionName}
                  setSectionName={setSectionName}
                />
              </Row>
            </Col>

            <Col
              xs={12}
              sm={12}
              lg={10}
              className={`navigation ${
                sectionName.toLowerCase() === "dashboard" ? "" : "gray"
              }`}
            >
              <Row className={`navigation__top`}>
                <TopNavigation
                  setSectionName={setSectionName}
                  sectionName={
                    sectionName.charAt(0).toUpperCase() + sectionName.slice(1)
                  }
                />
              </Row>
              <Row className={"main-content"}>
                <Switch>
                  <Route
                    exact
                    path={MANAGEMENT_BASE_PATH}
                    render={() => <Redirect push to={InnerRoutes.Dashboard} />}
                  />
                  <Route
                    path={InnerRoutes.Dashboard}
                    render={() => <Dashboard />}
                  />
                  <Route path={InnerRoutes.Users} render={() => <Users />} />
                  <Route
                    path={InnerRoutes.Products}
                    render={() => <Products />}
                  />
                  <Route
                    path={InnerUpdatesPaths.UpdatesPrefix}
                    component={UpdatesRoutes}
                  />
                  <Route
                    path={WidgetPaths.WidgetPrefix}
                    component={WidgetRoutes}
                  />
                </Switch>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
};
