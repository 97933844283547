export default function setProductIdParam(productId: string) {
  let currentProductId = window.location
    .toString()
    .split("/management/")[1]
    .split("/")[0];
  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname.replace(currentProductId, productId);
  window.history.pushState({ path: newUrl }, "", newUrl);
}
