import { MANAGEMENT_BASE_PATH } from "../../../constants";

export const ApplicationName = "WhatsNewApplication";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const LogoutActions = {
  LogoutCallback: "logout-callback",
  Logout: "logout",
  LoggedOut: "logged-out",
};

export const RegistrationActions = {
  Registration: "register",
};

export const AuthorizeActions = {
  Authorize: "authorize",
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed",
  Profile: "profile",
  Register: "register",
  IsLogin: "get-user-state",
  GoogleClientId: "google-client-id",
  MicrosoftApplicationId: "microsoft-application-id",
  CompanyId: "company",
};

const apiPrefix =
  window.location.protocol + "//" + window.location.host + "/api/authorization";

export const AuthorizationApiPaths = {
  Authorize: `${apiPrefix}/${AuthorizeActions.Authorize}`,
  Login: `${apiPrefix}/${AuthorizeActions.Login}`,
  Logout: `${apiPrefix}/${LogoutActions.Logout}`,
  Register: `${apiPrefix}/${RegistrationActions.Registration}`,
  IsLogin: `${apiPrefix}/${AuthorizeActions.IsLogin}`,
  GoogleClientId: `${apiPrefix}/${AuthorizeActions.GoogleClientId}`,
  MicrosoftApplicationId: `${apiPrefix}/${AuthorizeActions.MicrosoftApplicationId}`,
  CompanyId: `${apiPrefix}/${AuthorizeActions.CompanyId}`,
  GetInvitationToken: `${apiPrefix}/get-invitation-token`,
};

const prefix = "auth";

export const ApplicationPaths = {
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: `/${prefix}`,
  Login: `${MANAGEMENT_BASE_PATH}/${prefix}/${AuthorizeActions.Login}`,
  LoginFailed: `${MANAGEMENT_BASE_PATH}/${prefix}/${AuthorizeActions.LoginFailed}`,
  LoginCallback: `${MANAGEMENT_BASE_PATH}/${prefix}/${AuthorizeActions.LoginCallback}`,
  Register: `${MANAGEMENT_BASE_PATH}/${prefix}/${AuthorizeActions.Register}`,
  Profile: `${MANAGEMENT_BASE_PATH}/${prefix}/${AuthorizeActions.Profile}`,
  Logout: `${MANAGEMENT_BASE_PATH}/${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${MANAGEMENT_BASE_PATH}/${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${MANAGEMENT_BASE_PATH}/${prefix}/${LogoutActions.LogoutCallback}`,
};
