import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { AuthorizationApiPaths } from "../api-authorization/ApiAuthorizationConstants";

import styles from "../../../common/css/widget/widget.module.css";
import { InnerRoutes } from "../NavigationItems";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export class ConfigureWidget extends Component<
  {},
  {
    companyId: string;
    isCompanyIdLoading: boolean;
    isReactScriptView: boolean;
    toNews: boolean;
  }
> {
  jsWidgetScript: string = `<script src="https://${global.location.host}/authorization/widget/jsScript?id=ID" ></script>`;
  reactWidgetLink: string = `https://${global.location.host}/authorization/widget/news?id=ID`;

  constructor(props: any) {
    super(props);
    this.state = {
      companyId: "",
      isCompanyIdLoading: true,
      isReactScriptView: false,
      toNews: false,
    };
  }

  async componentDidMount() {
    document.body.className = styles.widgetBody;
    await this.getCompanyId();
  }

  componentWillUnmount() {
    document.body.className = "";
  }

  onCheckBoxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checkedValue = this.state.isReactScriptView;
    this.setState({
      isReactScriptView: !checkedValue,
    });
  };

  async getCompanyId() {
    await fetch(AuthorizationApiPaths.CompanyId)
      .then((response) => response.text())
      .then((response) =>
        this.setState({ companyId: response, isCompanyIdLoading: false })
      );
  }

  reactScriptView = (): JSX.Element => {
    const companyId = this.state.companyId;
    let widgetLink = this.state.isCompanyIdLoading
      ? "Loading..."
      : this.reactWidgetLink.replace("ID", companyId);

    return (
      <Fragment>
        <div>
          Create 'Widget.txt' file in your project and paste this code into it
        </div>
        <pre>{WidgetReactPlainTextCode.replace("WIDGET_LINK", widgetLink)}</pre>
        <div>
          Create 'Widget.css' file and put it into the same directory as
          'Widget.tsx'. Then copy and paste this code into 'Widget.css'
        </div>
        <pre>{WidgetReactPlainTextCSS}</pre>
      </Fragment>
    );
  };

  jsScriptView = (): JSX.Element => {
    const companyId = this.state.companyId;
    let widgetLink = this.state.isCompanyIdLoading ? (
      "Loading..."
    ) : (
      <pre>{this.jsWidgetScript.replace("ID", companyId)}</pre>
    );

    return (
      <Fragment>
        <div>Copy and paste this script on your page in the 'head' element</div>

        {widgetLink}
        <div>Then call the script on your page</div>
        <pre>{'<script>whatsNew("YOUR_COMPONENT_ID")</script>'}</pre>
      </Fragment>
    );
  };

  render() {
    if (this.state.toNews) {
      return <Redirect push to={InnerRoutes.Dashboard} />;
    }

    let scriptViewArea = this.state.isReactScriptView
      ? this.reactScriptView()
      : this.jsScriptView();

    return (
      <div className={styles.widget}>
        <FontAwesomeIcon
          onClick={() => this.setState({ toNews: true })}
          className="toNewsButton"
          icon={faChevronLeft as IconProp}
        />
        <div className={styles.editArea}>
          <div>Location!</div>
        </div>

        <div className={styles.exampleArea}>
          <div className={styles.reactSupportCheckbox}>
            <div>React</div>
            <input
              type="checkbox"
              name="isReactScriptView"
              onChange={this.onCheckBoxValueChange}
            />
          </div>
          {scriptViewArea}
        </div>
      </div>
    );
  }
}

const WidgetReactPlainTextCode = `import React, { Component, Fragment } from 'react';
import './Widget.css'

interface NewsElement {
    id: string,
    body: {
        label: string,
        title: string,
        version: string,
        body: string
    }
}
export default class WhatsNew extends Component<{}, { newsArray: [NewsElement], loading: boolean, clicked: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {
            newsArray: [{ id: '', body: { label: '', title: '', version: '', body: '' } }],
            loading: true,
            clicked: false
        }
    }

    componentDidMount() {
        fetch("WIDGET_LINK")
            .then(response => response.json())
            .then(data => this.setState({ newsArray: data, loading: false }))
    }

    renderNewsList = (): JSX.Element => {
        return (<Fragment>
            {this.state.newsArray.map((element: NewsElement) =>
                <div className="whatsNew-dropdown dropdown-child child-container" key={element.id}>
                    <h5>{element.body.version}</h5>
                    <h3>{element.body.title}</h3>
                    <span>{element.body.body}</span>
                </div>
            )}
        </Fragment>)
    }
    render() {
        let newsElements = this.state.loading ? 'Loading...' : this.renderNewsList();
        let news = (<Fragment></Fragment>);
        if (this.state.clicked) {
            news = (<div className='whatsNew-dropdown dropdown-child'>
                {newsElements}
            </div>)
        }
        if (! this.state.clicked) {
            news = (<Fragment></Fragment>);
        }
        return (
            <Fragment>
                <div className='whatsNew-dropdown'>
                    <div className='whatsNew-dropdown icon' onClick={() => this.state.clicked ? this.setState({ clicked: false }) : this.setState({ clicked: true })}>
                        <div className='whatsNew-dropdown indicator' />
                    </div>
                    {news}
                </div>
            </Fragment>
        )
    }
}`;

const WidgetReactPlainTextCSS = `.whatsNew-dropdown.icon {
    background-image: url('./gift.svg');
	width: 30px;
	height: 30px;
	position: relative;
	cursor: pointer;
}

.whatsNew-dropdown.icon.indicator {
	background-color: red;
	position: absolute;
	padding: 3px;
	bottom: 22px;
	right: 30px;
}

.whatsNew-dropdown.dropdown-child {
	display: block;
    position: fixed;
}

.whatsNew-dropdown.dropdown-child.child-container {
	float: left;
	position: relative;
	background-color: #eeeeee;
	border: 3px solid #fbfbfb;
	padding: 16px;
	width: 28em;
}

.whatsNew-dropdown.dropdown-child.child-container h5 {
	text-align: right;
	font-size: small;
	padding-bottom: 8px;
	color: gray;
}

.whatsNew-dropdown.dropdown-child.child-container h3 {
	padding-bottom: 28px;
}
.whatsNew-dropdown.dropdown-child.child-container span {
	color: black;
    font-size: medium;
}`;
