import React, { useState } from "react";
import { Placeholder } from "react-bootstrap";

import { IUpdate } from "../../../../common/dtos/NewsDto";
import { ActionsWithUpdate } from "../ActionsWithUpdate/ActionsWithUpdate";

import "./UpdatePreview.sass";

export interface IPreviewData {
  update: IUpdate;
  active?: boolean;
  refresh?: boolean;
}

interface IUpdatePreviewProps {
  previewData: IPreviewData;
  className?: string;
}

export const createPlaceholders = () => {
  let array = [];

  for (let i = 0; i < 7; i++) {
    array.push(
      <div className="update-preview__item padding" key={i}>
        <Placeholder
          as="p"
          className="cursor-default d-flex flex-column bg-transparent mb-5"
        >
          <Placeholder
            className="update-preview__item__placeholder cursor-default first"
            xs={5}
          />
          <Placeholder
            className="update-preview__item__placeholder cursor-default"
            xs={11}
          />
          <Placeholder
            className="update-preview__item__placeholder cursor-default"
            xs={10}
          />
          <Placeholder
            className="update-preview__item__placeholder cursor-default"
            xs={8}
          />
        </Placeholder>
      </div>
    );
  }
  return array;
};

export const UpdatePreview: React.FC<IUpdatePreviewProps> = ({
  previewData,
  className,
}) => {
  const [isLoading, setLoading] = useState(true);

  return previewData?.update?.id ? (
    <React.Fragment>
      <div className={`${className} update-preview__actions`}>
        <div className="update-preview__actions__text">Preview</div>
        <ActionsWithUpdate
          className={`update-preview__actions__item${
            previewData?.active ? "" : "inactive"
          }`}
          updateId={previewData?.update?.id}
          withText
        />
      </div>

      <div className={`update-preview`}>
        <div className="update-preview__item">
          <iframe
            id={"preview-iframe"}
            style={{ opacity: isLoading ? "0" : "1" }}
            className={`update-preview__iframe ${
              previewData?.active ? "" : "d-none"
            }`}
            title={"Update preview"}
            src={`${window.location.origin}/widget/preview?id=${previewData?.update?.id}`}
            onLoad={() => {
              setLoading(false);

              const iframe = document.getElementById(
                "preview-iframe"
              ) as HTMLIFrameElement;
              const content =
                iframe.contentDocument?.getElementsByClassName("content");
              if (content?.length !== 0) {
                let height = 0;
                if (content?.item(0) !== undefined) {
                  height = content!.item(0)!.scrollHeight + 80;
                }

                iframe.height = height + "px";
              }
            }}
          ></iframe>
        </div>
        {createPlaceholders()}
      </div>
    </React.Fragment>
  ) : null;
};
