import React, { useState } from "react";

import { NavDropdown } from "react-bootstrap";
import { RoleDTO } from "../../../../common/dtos/RoleDTO";
import { UsersApiPath } from "../UsersApiPath";
import { IUserInfoCallback } from "../UsersTable/UsersTable";
import { ScaleLoader } from "react-spinners";
import { Roles } from "../../Enums/Roles";
import { useAppSelector } from "../../../../StateManagement/Hooks";

import "./UserRole.sass";

interface IRoleChangerProps {
  rolesList: [RoleDTO] | undefined;
  user: IUserInfoCallback;
  usersList: [IUserInfoCallback] | undefined;
  currentUserRole: string | undefined;
  currentUserEmail: string | undefined;
  resetUsersAction: (newUsers: [IUserInfoCallback]) => void;
}

export const UserRole: React.FC<IRoleChangerProps> = ({
  rolesList,
  user,
  usersList,
  currentUserRole,
  currentUserEmail,
  resetUsersAction,
}) => {
  const [isChangingRoleLoading, setChangingRoleLoading] = useState(false);
  const currentProduct = useAppSelector((state) => state.currentProduct);

  const changeRole = async (newRole: RoleDTO, user: IUserInfoCallback) => {
    try {
      setChangingRoleLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: user.id,
          roleId: newRole.id,
          userEmail: user.email,
          productId: currentProduct.productId,
        }),
      };
      const response = await fetch(UsersApiPath.ChangeRole, requestOptions);
      if (!response.ok) {
        throw new Error("Unable to change role");
      }
      onChangeRoleAction(newRole, user);
    } catch (e) {
      console.error(e);
    } finally {
      setChangingRoleLoading(false);
    }
  };

  const onChangeRoleAction = (newRole: RoleDTO, user: IUserInfoCallback) => {
    const updatedUsers = usersList?.map((selectedUser) => {
      if (selectedUser.id === user.id) {
        const updatedUser = {
          ...selectedUser,
          role: newRole.name,
        };

        return updatedUser;
      }

      return selectedUser;
    });

    resetUsersAction(updatedUsers as [IUserInfoCallback]);
  };

  const onChangeRole = (newRole: RoleDTO, user: IUserInfoCallback) => {
    if (user.role !== newRole.name) {
      changeRole(newRole, user);
    }
  };

  if (isChangingRoleLoading) {
    return (
      <React.Fragment>
        <ScaleLoader color={"#0E9292"} />
      </React.Fragment>
    );
  } else if (
    currentUserRole === Roles.Writer ||
    currentUserEmail === user.email
  ) {
    return <React.Fragment>{user.role}</React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <NavDropdown
          id="nav-dropdown-light-example"
          title={user.role}
          menuVariant="light"
        >
          {rolesList?.map((role) => {
            return (
              <NavDropdown.Item
                key={role.name}
                onClick={() => onChangeRole(role, user)}
              >
                {role.name}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </React.Fragment>
    );
  }
};
