import React from "react";
import { Col, Row } from "react-bootstrap";

import { MicrosoftAuthorization } from "./MicrosoftAuthorization/MicrosoftAuthorization";

import "./AuthorizationButtons.sass";

export interface IUserAuthorizationData {
  token: string;
  company?: string;
}

interface IAuthorizationButtonsProps {}

export const AuthorizationButtons: React.FC<IAuthorizationButtonsProps> =
  () => {
    return (
      <Col className={"authorization"}>
        <Row className={"authorization__row"}>
          <MicrosoftAuthorization />
        </Row>
      </Col>
    );
  };
