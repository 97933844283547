import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthPath } from "../../../common/ApiRoutes/ApiRoutes";
import type { RootState } from "../../AppStore";
import { fetchCurrentUserProducts } from "../CurrentUserProductsList/UserProductsSlice";
import { FetchingStatus } from "../FetchingStatus";
import getProductIdParam from "../../../features/Management/CustomFunctions/GetProductIdParam";
import { UserDTO } from "../../../common/dtos/UserDto";

interface CurrentUserState {
  user: UserDTO;
  fetchingStatus: FetchingStatus;
}

export const fetchCurrentUser = createAsyncThunk(
  "users/getCurrentUser",
  async (obj, thunkAPI) => {
    try {
      const currState = thunkAPI.getState() as RootState;
      const productId = getProductIdParam();
      if (currState.currentUser.fetchingStatus === FetchingStatus.Loading) {
        return;
      }
      await thunkAPI.dispatch(setFetchingStatus(FetchingStatus.Loading));
      const response = await fetch(AuthPath.GetUserState);
      if (!response.ok) {
        throw new Error("Server error, cannot retrieve current user state");
      }
      const data = (await response.json()) as UserDTO;
      thunkAPI.dispatch(setCurrentUser(data));
      if (data && data.isAuthenticated === true) {
        thunkAPI.dispatch(
          fetchCurrentUserProducts({
            userEmail: data.email,
            productId: productId,
          })
        );
        return;
      } else {
        const redirectUrl = `${window.location.origin}/management/auth/login`;
        if (window.location.href !== redirectUrl) {
          window.location.replace(redirectUrl);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

const initialState: CurrentUserState = {
  user: new UserDTO(),
  fetchingStatus: FetchingStatus.NotStarted,
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserDTO>) => {
      Object.assign(state.user, action.payload);
    },
    setFetchingStatus: (state, action: PayloadAction<FetchingStatus>) => {
      state.fetchingStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.fetchingStatus = FetchingStatus.Succeeded;
    });
    builder.addCase(fetchCurrentUser.rejected, (state, action) => {
      state.fetchingStatus = FetchingStatus.Failed;
    });
  },
});

export const { setCurrentUser, setFetchingStatus } = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) => state;
