import React from "react";
import { useParams } from "react-router";
import { DesignProvider } from "@7pace/design";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ProductPaths } from "../../common/ApiRoutes/ApiRoutes";
import { ProductDTO } from "../../common/dtos/ProductDTO";

import "monday-ui-react-core/dist/main.css";
import "@7pace/design/dist/themes/7pace-monday.css";
import { News } from "./News/News";
import { Error } from "./Error/Error";

const queryClient = new QueryClient();

export const ProductNews: React.FC = () => {
  const { productUrlPath } = useParams<{ productUrlPath: string }>();
  const [product, setProduct] = React.useState<ProductDTO>();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const getApiBaseUrl = () => {
    const url = window.location.href;
    const currentBaseUrl = new URL(url).origin;

    return `${currentBaseUrl}/api/`;
  };

  const getProductByPath = async (productPath: string) => {
    try {
      const response = await fetch(ProductPaths.GetProductByPath + productPath);
      if (response.ok) {
        const product = await response.json();
        setProduct(product);
      } else {
        setErrorMessage("The requested product news do not exist");
      }
    } catch (e) {
      setErrorMessage(
        "Unable to get the requested product news. Please try again later."
      );
      console.error(e);
    }
  };

  React.useEffect(() => {
    getProductByPath(productUrlPath);
  }, [productUrlPath]);

  const renderWhatsNew = () => {
    if (product && product?.productId) {
      return (
        <News
          title={"What's New in 7pace Timetracker"}
          baseUrl={getApiBaseUrl()}
          productId={product.productId}
        />
      );
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <DesignProvider>
        <Container>
          {errorMessage === null ? (
            renderWhatsNew()
          ) : (
            <Error message={errorMessage} />
          )}
        </Container>
      </DesignProvider>
    </QueryClientProvider>
  );
};

const Container = styled.div`
  max-width: 768px;
  height: 100%;

  padding: 0 ${(p) => p.theme.spacing.m};
  margin: 0 auto;
`;
