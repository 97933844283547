import React from 'react';
import { Row } from 'react-bootstrap';

import './CustomCardTopCornerInfo.sass';

interface ICustomCardTopCornerInfoProps {
    cornerInfo: string,
    cornerAdditionalInfo?: string,
}

export const CustomCardTopCornerInfo: React.FC<ICustomCardTopCornerInfoProps> = ({ cornerInfo, cornerAdditionalInfo }) => {

    return <React.Fragment>
            <Row className={"corner-info__main justify-content-end align-items-center"}>
                {cornerInfo}
            </Row>

            {cornerAdditionalInfo === undefined ? '' : (
                <Row className={"corner-info__additional d-none d-lg-flex justify-content-end align-items-center"}>
                    {cornerAdditionalInfo}
                </Row>
            )}
    </React.Fragment>
}
