import { MANAGEMENT_BASE_PATH } from "../../constants";

interface IMenuItem {
  sectionName: string;
  route: string;
  image: string;
}
export const productIdParam = "/:productId";

export const InnerRoutes = {
  Dashboard: MANAGEMENT_BASE_PATH + productIdParam + "/dashboard",
  ManageUpdates: MANAGEMENT_BASE_PATH + productIdParam + "/updates",
  Users: MANAGEMENT_BASE_PATH + productIdParam + "/users",
  Products: MANAGEMENT_BASE_PATH + productIdParam + "/products",
};

export const navigationMenuItems: IMenuItem[] = [
  {
    sectionName: "Dashboard",
    route: InnerRoutes.Dashboard,
    image: "/dashboard.svg",
  },
  {
    sectionName: "Manage Updates",
    route: InnerRoutes.ManageUpdates,
    image: "/updates.svg",
  },
  // {
  //     sectionName: "Widget Config",
  //     route: "/config",
  //     image: "/config.svg"
  // },
  // {
  //     sectionName: "Reporting",
  //     route: "/reporting",
  //     image: "/report.svg"
  // },
  {
    sectionName: "Users",
    route: InnerRoutes.Users,
    image: "/users.svg",
  },
  {
    sectionName: "Products",
    route: InnerRoutes.Products,
    image: "/products.svg",
  },
];
