import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { CustomCardButton } from "./CustomCardButton/CustomCardButton";
import { CustomCardImage } from "./CustomCardImage/CustomCardImage";
import { CustomCardTopCornerInfo } from "./CustomCardTopCornerInfo/CustomCardTopCornerInfo";

import "./CustomCard.sass";
import { ScaleLoader } from "react-spinners";

interface ICardProps {
  loading: boolean;
  cornerInfo: string;
  cornerAdditionalInfo?: string;
  imageLink: string;
  buttonUrl?: string;
  buttonText?: string;
  buttonIconUrl?: string;

  additionalInfo: string;
  linkUrl?: string;
  linkText?: string;
}

export const CustomCard: React.FC<ICardProps> = ({
  loading,
  imageLink,
  additionalInfo,
  linkUrl,
  linkText,
  cornerInfo,
  cornerAdditionalInfo,
  buttonUrl,
  buttonText,
  buttonIconUrl,
}) => {
  return (
    <React.Fragment>
      <Container className={"card"}>
        <Row className={"justify-content-center align-items-center"}>
          <div className="card__info  d-flex flex-column justify-content-start align-items-end align-content-end">
            {loading ? (
              <ScaleLoader color={"#E7F4F4"} />
            ) : (
              <CustomCardTopCornerInfo
                cornerInfo={cornerInfo}
                cornerAdditionalInfo={cornerAdditionalInfo}
              />
            )}
          </div>

          <div className="card__image d-none d-lg-block">
            <CustomCardImage imageLink={imageLink} />
          </div>

          <div className={`card__button`}>
            <CustomCardButton
              buttonUrl={buttonUrl}
              buttonText={buttonText}
              buttonIconUrl={buttonIconUrl}
            />
          </div>

          <Row
            className={
              "card__additional-info justify-content-center align-items-center"
            }
          >
            {loading ? <ScaleLoader color={"#E7F4F4"} /> : additionalInfo}
          </Row>

          <Row
            className={"card__link justify-content-center align-items-center"}
          >
            {linkUrl === undefined ? (
              ""
            ) : (
              <Link
                className={
                  "card__link d-flex justify-content-center align-items-center"
                }
                to={linkUrl}
              >
                {linkText}
              </Link>
            )}
          </Row>
        </Row>
      </Container>
    </React.Fragment>
  );
};
