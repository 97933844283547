import React, { useEffect, useState } from "react";

import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

import { UpdatesTable } from "./UpdatesTable/UpdatesTable";
import { UpdatesPaths } from "../UpdatesConstants";
import { IPreviewData, UpdatePreview } from "../UpdatePreview/UpdatePreview";
import { useAppSelector } from "../../../../StateManagement/Hooks";
import { FetchingStatus } from "../../../../StateManagement/Features/FetchingStatus";
import { productIdParam } from "../../NavigationItems";
import replaceParameterValue from "../../CustomFunctions/ReplaceParameterValue";

import "./UpdatesList.sass";

interface IUpdatesListProps {}

export const UpdatesList: React.FC<IUpdatesListProps> = () => {
  const [previewData, setPreviewData] = useState<IPreviewData>();
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchingStatus =
      currentUserProductsFetchingStatus === FetchingStatus.NotStarted ||
      currentUserProductsFetchingStatus === FetchingStatus.Loading
        ? true
        : false;
    setIsLoading(fetchingStatus);
  }, [currentUserProductsFetchingStatus]);

  if (isLoading) {
    return (
      <React.Fragment>
        <ScaleLoader color={"#0E9292"}></ScaleLoader>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Col className={"updates-list"} xs={10} sm={10} md={8} lg={9} xl={9}>
          <Link
            className={"button-link mb-3 "}
            to={replaceParameterValue(
              UpdatesPaths.Create,
              productIdParam,
              `/${currentProduct.productId!}`
            )}
          >
            <Button variant="add light d-flex justify-content-center align-items-center">
              <div className="btn-add__icon"></div>
              <div className="btn-add__text">Add new</div>
            </Button>
          </Link>
          <UpdatesTable setPreviewData={setPreviewData} />
        </Col>
        <Col
          md={3}
          lg={3}
          xl={3}
          className={`d-none d-lg-flex preview d-flex flex-column`}
          style={{ opacity: `${previewData?.active ? "1" : "0"}` }}
        >
          <UpdatePreview
            previewData={previewData!}
            className={"preview__container"}
          />
        </Col>
      </React.Fragment>
    );
  }
};
