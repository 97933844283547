import React, { useEffect, useState } from "react";
import { Button, Col, Row, OverlayTrigger, Popover } from "react-bootstrap";

import { UsersTable } from "./UsersTable/UsersTable";
import { useAppSelector } from "../../../StateManagement/Hooks";
import { ProductInvitationTokenPrefix } from "../../../common/ApiRoutes/ApiRoutes";

import "./Users.sass";

interface IUsersProps {}

export const Users: React.FC<IUsersProps> = () => {
  const currentProduct = useAppSelector((state) => state.currentProduct);

  useEffect(() => {
    const getLink = () => {
      setInvitationLink(
        ProductInvitationTokenPrefix + currentProduct.invitationToken
      );
    };
    getLink();
  }, [currentProduct.productId, currentProduct.invitationToken]);

  const [invitationLink, setInvitationLink] = useState("");
  const resetUsers = React.useRef<any>();

  const invitationUsers = (
    <React.Fragment>
      <OverlayTrigger
        trigger="click"
        placement="right"
        rootClose={true}
        overlay={
          <Popover>
            <Popover.Header>
              This is your link to invite users to this product
            </Popover.Header>
            <Popover.Body>{invitationLink}</Popover.Body>
          </Popover>
        }
      >
        <Button variant="add light d-flex justify-content-center mb-3 align-items-center">
          <div className="btn-add__icon"></div>
          <div className="btn-add__text">Invite users</div>
        </Button>
      </OverlayTrigger>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Row>
        <Col xl={10} lg={10} md={9} sm={9} xs={9}>
          <Row>
            <Col xs={2}>{invitationUsers}</Col>
          </Row>
          <UsersTable resetUsers={resetUsers} />
        </Col>
      </Row>
    </React.Fragment>
  );
};
