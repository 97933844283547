import React from "react";
import { Row, Image } from "react-bootstrap";
import { imageSource } from "../../../../../common/ApiRoutes/ContentRoutes";

import "./CustomCardImage.sass";

interface ICustomCardImageProps {
  imageLink: string;
}

export const CustomCardImage: React.FC<ICustomCardImageProps> = ({
  imageLink,
}) => {
  return (
    <React.Fragment>
      <Row
        className={
          "custom-image d-none d-lg-flex justify-content-center align-items-center"
        }
      >
        <Image
          className={"custom-image__image"}
          src={`${imageSource}${imageLink}`}
        />
      </Row>
    </React.Fragment>
  );
};
