import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Form, InputGroup } from "react-bootstrap";

import { ProductsTable } from "./ProductsTable/ProductsTable";
import { ProductDTO } from "../../../common/dtos/ProductDTO";
import { ProductPaths } from "../../../common/ApiRoutes/ApiRoutes";
import { useAppDispatch, useAppSelector } from "../../../StateManagement/Hooks";
import { fetchCurrentUserProducts } from "../../../StateManagement/Features/CurrentUserProductsList/UserProductsSlice";
import { FetchingStatus } from "../../../StateManagement/Features/FetchingStatus";
import { fetchCurrentUser } from "../../../StateManagement/Features/CurrentUser/CurrentUserSlice";
import { ScaleLoader } from "react-spinners";
import {
  validateProductName,
  validateProductPath,
} from "../CustomFunctions/ValidateProduct";

import "./Products.sass";
import { getErrorResponse } from "../CustomFunctions/ProductResponse";

interface IProductsProps {}

export const Products: React.FC<IProductsProps> = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const currentProduct = useAppSelector((state) => state.currentProduct);
  const currentUserProductsState = useAppSelector(
    (state) => state.userProducts
  );
  const currentUserFetchingStatus = useAppSelector(
    (state) => state.currentUser.fetchingStatus
  );
  const currentUserProductsFetchingStatus = useAppSelector(
    (state) => state.userProducts.fetchingStatus
  );
  const fetchingStatus =
    currentUserFetchingStatus === FetchingStatus.Loading ||
    currentUserProductsFetchingStatus === FetchingStatus.Loading
      ? true
      : false;
  const [isProductsLoading, setProductsLoading] = useState(fetchingStatus);
  const [invalidProductName, setInvalidProductName] = useState(false);
  const [invalidProductPath, setInvalidProductPath] = useState(false);
  const [productNameAlreadyExists, setProductNameAlreadyExists] =
    useState(false);
  const [productPathAlreadyExists, setProductPathAlreadyExists] =
    useState(false);
  const hasProductNameValidationErrors =
    invalidProductName || productNameAlreadyExists ? true : false;
  const hasProductPathValidationErrors =
    invalidProductPath || productPathAlreadyExists ? true : false;

  const [validated, setValidated] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [newProductPath, setNewProductPath] = useState("");

  useEffect(() => {
    if (currentUser.email) {
      dispatch(
        fetchCurrentUserProducts({
          userEmail: currentUser.email,
          productId: currentUser.productId,
        })
      );
      return;
    }
    dispatch(fetchCurrentUser());
  }, [dispatch, currentUser]);

  const fetchProducts = async () => {
    setProductsLoading(true);
    await dispatch(
      fetchCurrentUserProducts({
        userEmail: currentUser.email,
        productId: currentUser.productId,
      })
    );
    setProductsLoading(false);
  };

  const addProduct = async () => {
    try {
      let newProductDTO: ProductDTO = new ProductDTO();
      newProductDTO.productName = newProductName;
      newProductDTO.productPath = newProductPath;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newProductDTO),
      };
      const response = await fetch(ProductPaths.AddProduct, requestOptions);
      if (!response.ok) {
        throw new Error("Unable to add product");
      }

      const errorResponse = await getErrorResponse(response);

      if (errorResponse !== null) {
        setProductNameAlreadyExists(errorResponse.productNameExists);
        setProductPathAlreadyExists(errorResponse.productPathExists);

        const alreadyExistingValidationErrors =
          errorResponse.productNameExists || errorResponse.productPathExists;

        if (alreadyExistingValidationErrors) {
          return;
        }
      }

      setShowModal(false);
      setProductNameAlreadyExists(false);
      dispatch(
        fetchCurrentUserProducts({
          userEmail: currentUser.email,
          productId: currentUser.productId,
        })
      );
      setNewProductName("");
      setNewProductPath("");
      setValidated(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onAddProduct = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const invalidProductName = await validateProductName(newProductName);
    const invalidProductPath = await validateProductPath(newProductPath);
    setInvalidProductName(invalidProductName);
    setInvalidProductPath(invalidProductPath);

    if (invalidProductName || invalidProductPath) {
      return;
    }
    if (invalidProductName === false) {
      await addProduct();
    }
  };

  const addProductBtn = (
    <React.Fragment>
      <Button
        onClick={() => setShowModal(true)}
        variant="add light d-flex justify-content-center mb-3 align-items-center"
      >
        <div className="btn-add__icon"></div>
        <div className="btn-add__text">Add new product</div>
      </Button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Col xl={10} lg={10} md={9} sm={9} xs={9}>
        {addProductBtn}
        {isProductsLoading ? (
          <ScaleLoader color={"#E7F4F4"} />
        ) : (
          <ProductsTable
            resetProducts={fetchProducts}
            products={currentUserProductsState.userProducts}
            currentProduct={currentProduct}
          />
        )}
      </Col>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add new product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="newProductForm" validated={validated}>
            <Form.Group
              onSubmit={() => {}}
              className="mb-3"
              controlId="formProductName"
            >
              <Form.Label>Product name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Enter product name"
                  required
                  isInvalid={hasProductNameValidationErrors}
                  value={newProductName}
                  onChange={(e) => {
                    setNewProductName(e.target.value);
                  }}
                />
                {invalidProductName ? (
                  <Form.Control.Feedback type="invalid">
                    Invalid product name; must be a minimum of 3 and a maximum
                    of 20 characters long
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
                {productNameAlreadyExists ? (
                  <Form.Control.Feedback type="invalid">
                    Product name already exists.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
              </InputGroup>

              <br />

              <Form.Label>Product URL path</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Enter product path"
                  required
                  isInvalid={hasProductPathValidationErrors}
                  value={newProductPath}
                  onChange={(e) => {
                    setNewProductPath(e.target.value);
                  }}
                />
                {invalidProductPath ? (
                  <Form.Control.Feedback type="invalid">
                    Invalid product path; must be a minimum of 3 and a maximum
                    of 20 characters long, and must consist of only letters,
                    numbers and dashes.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
                {productPathAlreadyExists ? (
                  <Form.Control.Feedback type="invalid">
                    Product URL path already exists.
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            form="newProductForm"
            variant="primary"
            type="submit"
            className="confirm-action"
            onClick={(e) => {
              onAddProduct(e);
            }}
          >
            Add product
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
