import React, { Component } from "react";
import { Redirect } from "react-router";

import { UpdatesPaths } from "./UpdatesConstants";
import styles from "../../../common/css/news/delete.module.css";
import { UpdatesApiPaths } from "../../../common/ApiRoutes/ApiRoutes";
import { dashboardStorageKeys } from "../StorageKeys";
import { productIdParam } from "../NavigationItems";
import replaceParameterValue from "../CustomFunctions/ReplaceParameterValue";

export class DeleteUpdate extends Component<
  { history: any; match: any },
  { id: string; confirm: boolean; productId: string }
> {
  static displayName = DeleteUpdate.name;
  constructor(props: any) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      productId: this.props.match.params.productId,
      confirm: false,
    };

    this.deleteNews = this.deleteNews.bind(this);
  }

  deleteNews() {
    this.deleteNewsById(this.state.id);
  }

  async deleteNewsById(id: string) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(id),
    };

    await fetch(UpdatesApiPaths.Delete, requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ confirm: data }));
  }

  render() {
    if (this.state.confirm) {
      window.localStorage.removeItem(dashboardStorageKeys.update);
      return (
        <Redirect
          push
          to={replaceParameterValue(
            UpdatesPaths.News,
            productIdParam,
            `/${this.state.productId!}`
          )}
        />
      );
    }
    return (
      <div className={styles.container}>
        <p>Are you sure you want to delete this news?</p>
        <div className={styles.buttons}>
          <button
            className={styles.backButton}
            onClick={() => this.setState({ confirm: true })}
          >
            No
          </button>
          <button
            className={styles.confirmButton}
            onClick={() => this.deleteNews()}
          >
            Yes
          </button>
        </div>
      </div>
    );
  }
}
