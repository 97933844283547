const apiPath =
  window.location.protocol +
  "//" +
  window.location.host +
  "/api/userManagement";
export const UsersApiPath = {
  GetUsers: `${apiPath}/get-users`,
  DeleteUser: `${apiPath}/delete-user?userEmail=`,
  GetRoles: `${apiPath}/get-roles`,
  ChangeRole: `${apiPath}/change-role`,
  LeaveProduct: `${apiPath}/leave-product?productId=`,
};
